<template>
  <v-container fluid>
    <div v-if="session.version == 'desktop'">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" @click="listaImpresion()" icon>
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Lista de Impresion</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" @click="refresh()" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refrescar</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" @click="nuevo()" icon>
            <v-icon>mdi-new-box</v-icon>
          </v-btn>
        </template>
        <span>Nueva Orden</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" @click="mobile()" icon>
            <v-icon>mdi-cellphone</v-icon>
          </v-btn>
        </template>
        <span>Version Mobile</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-toolbar dense color="secondary">
        <v-spacer></v-spacer>
        <v-btn dark @click="listaImpresion()" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn dark @click="refresh()" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn dark @click="nuevo()" icon>
          <v-icon>mdi-new-box</v-icon>
        </v-btn>
        <v-btn dark @click="desktop()" icon>
          <v-icon>mdi-monitor</v-icon>
        </v-btn>
        <v-btn dark @click="ordenes()" icon>
          <v-icon>mdi-arrow-expand-all</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
  </v-container>
</template>

<script>
import { Session } from "../controllers/DataController";
import Cookies from "js-cookie";
import { db } from '../db';

export default {
  props: {
    idOrder: {
      type: Number,
      default: 0,
    },
    idComponent: {
      type: String,
      default: "tipoPago",
    },
  },
  data() {
    return {
      tipo: "",
      session: new Session(),
    };
  },
  methods: {
    listaImpresion() {
      this.$emit("onlistaimpresion");
    },
    nuevo() {
      this.$emit("nuevo");
    },
    mobile() {
      Cookies.set("POS_VERSION", "mobile");
      this.session = new Session();
      this.$emit("mobile");
    },
    ordenes() {
      this.$emit("ordenes");
    },
    desktop() {
      Cookies.set("POS_VERSION", "desktop");
      this.session = new Session();
      this.$emit("desktop");
    },
    showDropDown(id) {
      document.getElementById(id).classList.toggle("show");
    },
    selectOption(target, option) {
      if (target.parentElement.classList.contains("show")) {
        target.parentElement.classList.remove("show");
      }
      this.$emit("optionselected", option);
    },
    async refresh() {
      //* Limpiamos todo para que fuelva a cargar
      await db.articulos.clear();
      await db.grupos.clear();
      await db.tiposPago.clear();
      await db.motivosDescuento.clear();
      await db.motivosDevolucion.clear();
      await db.colonias.clear();
      await db.impresoras.clear();
      await db.meseros.clear();
      await db.terminalPago.clear();
      await db.tiposVenta.clear();
      await db.clientes.clear();
      await db.mesas.clear();
      await db.repartidores.clear();
      await db.articulo_configuraciones.clear();
      location.reload();
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="localValue.visible"
    persistent
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <PrinterDialog v-model="printer" :impresoras="impresoras" />
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Cancelar / Devolver </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              rounded
              type="number"
              min="0"
              v-model="orden"
              label="Orden"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-footer>
        <v-btn rounded block color="primary" @click="buscar()">Buscar</v-btn>
      </v-footer>
    </v-card>

    <v-dialog
      v-model="orden_visible"
      persistent
      max-width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="localValue.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Datos de la Orden </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                readonly
                :value="POSEnc.intOrden"
                label="Orden"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                readonly
                :value="isMoney(POSEnc.dblSubtotal)"
                label="Subtotal"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                readonly
                :value="isMoney(POSEnc.dblIVA)"
                label="IVA"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="isMoney(POSEnc.dblTotal)"
                label="Total"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :value="getServicio(POSEnc.intPOSTipoServicio)"
                label="Servicio"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="toDateString(POSEnc.datFecha, 2)"
                label="Fecha"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="POSEnc.strUsuarioPOS"
                label="Usuario"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
          <v-col>
            <v-card>
              <v-card-title>Cancelacion</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.usuario"
                        :rules="[() => !!usuario.usuario || 'Llene este campo']"
                        append-icon="mdi-account"
                        label="Usuario"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.password"
                        :rules="[() => !!usuario.password || 'Llene este campo']"
                        append-icon="mdi-shield-lock"
                        type="password"
                        label="Password"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-radio-group ref="tipo" v-model="intCancelacionDevolucion" row>
                        <v-radio label="Devolucion" :value="8"></v-radio>
                        <v-radio label="Cancelacion" :value="9"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        :items="motivos"
                        outlined
                        ref="motivo"
                        item-text="strNombre"
                        item-value="intPOSMotivoCancelacion"
                        label="Motivo"
                        v-model="motivoCancelacion"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        ref="comentario"
                        outlined
                        label="Comentario"
                        v-model="comentario"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
          <v-row>
            <v-col>
              <v-btn block color="secondary" @click="cancelar()">Cancelar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- <v-dialog
        v-model="autorizacion"
        persistent
        max-width="400"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon dark @click="localValue.visible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title> Autorizacion</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="usuario.usuario"
                  :rules="[() => !!usuario.usuario || 'Llene este campo']"
                  append-icon="mdi-account"
                  label="Usuario"
                  outlined
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="usuario.password"
                  :rules="[() => !!usuario.password || 'Llene este campo']"
                  append-icon="mdi-shield-lock"
                  type="password"
                  label="Password"
                  outlined
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block color="primary" @click="autorizar()"> Autorizar </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog> -->
    </v-dialog>
  </v-dialog>
</template>

<script>
import { Session, Toast, Usuario,Dialog } from "../controllers/DataController.js";
import { POSEncFunctions ,POSLogImpresion} from "../controllers/POSController.js";
import { isMoney, toDateString } from "../js/utilities.js";
import PrinterDialog from "./PrinterDialogComponent.vue";

export default {
  props: {
    value: {
      required: true,
    },
    motivos: {
      type: Array,
      default: () => [],
    },
    impresoras: {
      type: Array,
      default: () => [],
    },
    POSInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PrinterDialog,
  },
  data() {
    return {
      localValue: this.value,
      orden: 0,
      session: new Session(),
      POSEnc: {},
      ordenFunctions: new POSEncFunctions(),
      orden_visible: false,
      autorizacion: false,
      usuario: new Usuario(),
      loading: false,
      motivoCancelacion:null,
      comentario:null,
      printer: new Dialog(0, 0, "", "", ""),
      log: new POSLogImpresion(new Session(), this.$config.api_backend_route),
      intCancelacionDevolucion:null
      
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
  },
  methods: {
    toDateString: toDateString,
    getServicio(servicio) {
      if (servicio == 1) return "MESA";
      else if (servicio == 2) return "DOMICILIO";
      else if (servicio == 3) return "LLEVAR";
      else return "";
    },
    async autorizar() {
      this.loading = true;
      try {

        console.log(this.intCancelacionDevolucion)
        if (
          this.motivoCancelacion == null ||
          this.motivoCancelacion == undefined ||
          this.motivoCancelacion == 0
        ) {
          // this.$refs.motivoCancelacion.focus();
          throw "Seleccione un motivo";
        }
        if (this.comentario == null || this.comentario == "") {
          this.$refs.comentario.focus();
          throw "Agregue un comentario";
        }
        if (this.intCancelacionDevolucion == null) {
          // this.$refs.intCancelacionDevolucion.focus();
          throw "Seleccione un tipo";
        }

        if (!(await this.usuario.validate_cancelacion()))
          throw "Usuario no autorizado para realizar esta accion";

        await this.ordenFunctions.cancelarEnc(
            this.POSEnc.intPOSEnc.toString(),
            new Session().terminal,
            this.usuario.usuario,
            this.motivoCancelacion,
            this.comentario , 
            this.intCancelacionDevolucion     
          )
        
        this.autorizacion = false;
        this.orden_visible = false;
        this.localValue.visible = false;
        this.usuario = new Usuario();

        this.POSEnc.POSInfo = this.POSInfo;
        this.POSEnc.StrTerminal = this.session.terminal;
        this.POSEnc.strUsuarioCancelacion=this.usuario.usuario;
        this.POSEnc.strComentarioCancelacion=this.comentario;
        this.printer.event ="cancelacion";
        this.printer.visible = true;
        this.printer.log = "Cancelacion";
        this.printer.printable =  this.POSEnc;

        this.$toast.success("Cancelacion exitosa de la orden " + this.orden, new Toast());
        this.orden = 0;
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
    async cancelar() {
      // this.autorizacion = true;
      this.autorizar();
    },
    isMoney: isMoney,
    async buscar() {
      this.loading = true;
      try {
        this.POSEnc = await this.ordenFunctions.retrieve(this.orden);
        if (this.POSEnc.intEstatus != 4)
          throw "Esta orden no puede ser cancelada, esta en preparacion o ya se encuentra cancelada";
       
        this.orden_visible = true;
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>

<template>
  <v-row>
    <v-col :md="col_size" v-for="orden in ordenes_carousel" :key="orden.intPOSEnc">
      <v-card min-height="100%" @click="seleccionar(orden)" dark :color="getcolor(orden)" :img="getImage(orden)">
        <v-card-title>
          <v-spacer></v-spacer>
          Orden {{ orden.intOrden }} 
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row align="center" class="mx-0">
            <b>{{ getLabel(orden.intPOSTipoServicio) }}</b>
          </v-row>
          <v-row align="center" class="mx-0">
            <b>{{ getEstatus(orden.intEstatus) }}</b>
          </v-row>
          <v-row v-if="orden.intPOSTipoServicio == 1" align="center" class="mx-0">
            <b>{{ orden.strMesa }}</b>
          </v-row>
          <v-row v-if="orden.cliente != null" align="center" class="mx-0">
            <b>{{ orden.cliente.strNombre + " " + orden.cliente.strTelefono }}</b>
          </v-row>
          <v-row v-if="orden.intPOSTipoServicio == 3" align="center" class="mx-0">
            <b>{{ orden.strMesa }}</b>
          </v-row>
          <v-row v-if="orden.intPOSTipoServicio == 1" align="center" class="mx-0">
            <b>{{ getMesero(orden) }}</b>
          </v-row>
          <v-row align="center" class="mx-0">
            <b>{{ toDateString(orden.datFecha, 2) }}</b>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="2">
      <v-btn color="primary" @click="maximizar()" fab small dark>
        <v-icon>mdi-arrow-expand-all</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { toDateString } from "../js/utilities";

export default {
  props: {
    principalColor: {
      type: String,
    },
    ordenes: {
      type: Array,
      default: () => [],
    },
    minOrdenes: {
      type: Number,
      default: 5,
    },
    meseros: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toDateString: toDateString,

    getLabel(intPOSTipoServicio) {
      if (intPOSTipoServicio == 1) return "MESA";
      else if (intPOSTipoServicio == 2) return "DOMICILIO";
      else if (intPOSTipoServicio == 3) return "LLEVAR";
    },
    maximizar() {
      this.$emit("mostrarordenes");
    },
    seleccionar(orden) {
      this.$emit("seleccionar", orden);
    },
    getImage(orden) {
      if (orden.intPOSTipoServicio == 1) return "/imagenes/table.jpg";
      else if (orden.intPOSTipoServicio == 2 && orden.intEstatus ==3) return "/images/domicilio.jpg";
      else if (orden.intPOSTipoServicio == 3) return "/imagenes/takeaway.jpg";
    },
    getMesero(orden) {
      const mesero = this.meseros.find(
        (mesero) => mesero.intPOSMesero == orden.intPOSMesero
      );
      if (mesero != null) {
        return mesero.strNombre + " " + mesero.strApellidoPaterno;
      } else {
        return "NO ASIGNADO";
      }
    },
    getcolor(orden) {
      if (orden.intPOSTipoServicio == 1) return "primary";
      else if (orden.intPOSTipoServicio == 2) return "green";
      else if (orden.intPOSTipoServicio == 3) return "secondary";
    },
    getEstatus(estatus) {
      if (estatus == 1) return "REGISTRADA";
      else if (estatus == 2) return "EN PROCESO";
      else if (estatus == 3) return "TERMINADA";
      else if (estatus == 4) return "PAGADA";
    },
  },
  computed: {
    col_size() {
      if (this.minOrdenes == 5) {
        return "2";
      } else {
        return "2";
      }
    },
    ordenes_carousel() {
      if (this.ordenes.length < 5) {
        let ordenes = this.ordenes;
        return ordenes.sort((a, b) => (a.intPOSEnc > b.intPOSEnc ? 1 : -1));
      } else
        return this.ordenes
          .slice(this.ordenes.length - this.minOrdenes, this.ordenes.length)
          .sort((a, b) => (a.intPOSEnc > b.intPOSEnc ? 1 : -1));
    },
  },
};
</script>

<style></style>

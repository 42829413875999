<template>
  <div>
    <Loader v-model="loading" />
    <v-dialog v-model="activeOpciones" width="500px">
      <v-card hover>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="activeOpciones = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Acciones</v-toolbar-title>
        </v-toolbar>
        <v-list dense nav>
          <v-list-item
            @click="seleccionaOpcion(accion.valor)"
            v-for="accion in acciones"
            :key="accion.valor"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ accion.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <SepararCuentaUI
      @separado="separado"
      @loading="setLoading"
      :terminal="session.terminal"
      v-model="separar_cuenta"
      :posenc="POSEnc"
    />
    <GoogleDistance v-model="googleDistance" @completed="pagarDomicilioDistancia" />
    <CortesiaUI @guardar="guardarCortesia" v-model="cortesia" :posenc="POSEnc" :descuentos="motivosDescuento" 
    :impresoras="impresoras" :POSInfo="POSInfo"/>
    <DomicilioCambio
      @continuar="prepararOrdenDomicilio"
      v-model="servicio_domicilio_pago"
    />
    <ListadoImpresion
      :terminal="terminal"
      :POSInfo="POSInfo"
      :impresoras="impresoras"
      v-model="listadoImpresion"
    />
    <Autorizacion
      @loading="setLoading"
      :version="2"
      @authorization="authorized"
      v-model="auth"
    />
    <FirmaAutorizacion @autorizar="autorizarFirma" v-model="autoriza" />
    <ArticuloConfiguracion
      @finish="saveConfiguration"
      :steps="configurationArticulo.steps"
      v-model="configurationArticulo"
      :key="configurationKey"
    />
    <PrinterDialog @loading="setLoading" v-model="printer" :impresoras="impresoras" />
    <Validate @login="cerrarSesion" v-model="errorLogin" />

    <ConfirmDialog
      :visible="confirm.visible"
      :text="confirm.text"
      :title="confirm.title"
      :aceptar-text="confirm.aceptarText"
      :cancelar-text="confirm.cancelarText"
      :event="confirm.event"
      @cancelar="confirm.visible = false"
      @aceptar="aceptarConfirm"
    />
    <DescuentoDetalle
      @guardar="guardarDescuento"
      v-model="descuentoDet"
      :descuentos="motivosDescuento"
    />
    <DescuentoGeneral
      @guardar="guardarDescuentoEnc"
      v-model="descuentoEnc"
      :descuentos="motivosDescuento"
    />
    <CancelarDetalle
      @cancelar="cancelarDet.isVisible = false"
      @guardar="guardarCancelacionDet"
      v-model="cancelarDet"
      :motivos="motivosDevolucion"
    />
    <CancelarDevolver @loading="setLoading" v-model="cancelarDevolverVisible" :POSInfo="POSInfo"
    :motivos="motivosDevolucion" :impresoras="impresoras" />
    <CancelarEncUI
      v-model="cancelarEnc"
      @cancelar="cancelarEnc.visible = false"
      @guardar="cancelarOrden"
      :motivos="motivosDevolucion"
    />
    <ArticuloAgregar
      v-model="modalAgregar"
      :articulo="articulo"
      @guardar="articuloValidado"
    />
    <ArticuloAgregarEspecial
      @guardar="guardarArticuloEspecial"
      v-model="modalAgregarEspecial"
    />
    <ModalPago
      v-model="POSEnc"
      :visible="modalpago.visible"
      :terminalespago="terminalespago"
      @loading="setLoading"
      @cerrar="modalpago.visible = false"
      :tipospago="tiposPago"
      @pago="realizarPago"
      :envio="envio_ticket"
    />
    <ModalOrdenes
      v-model="modalOrdenes"
      :ordenes="ordenes"
      @loading="setLoading"
      :meseros="meseros"
      :register="intPOSRegister"
      @seleccionar="seleccionarOrden"
      @reimprimir="printPOSPago"
    />
    <ModalOrdenOpcion
      v-model="modalOrdenOpcionVisible"
      :posenc="POSEnc"
      @cortesia="abrirCortesia"
      @juntar="juntarPOSEnc"
      @separar="separarPOSEnc"
      @eliminar="eliminarPOSEnc"
      @cancelar="cancelarPOSEnc"
      @agregar="agregarPOSDet"
      @descuento="descuentoPOSEnc"
      @prepago="printPOSPrepago"
    />
    <ModalDetalleEdicion
      :posdet="detalleSeleccionado"
      v-model="detalleEdicion"
      @guardar="guardarEdicionDetalle"
    />
    <ModalDetalleOpciones
      v-model="opcionesDetalle"
      @eliminar="eliminarDetalle()"
      @editar="editarDetalle()"
      @descuento="descuentoDetalle"
      @cancelar="cancelarDetalle"
      @reconfigurar="reconfigurarDetalle()"
    />
    <RegistroInventario
      @loading="setLoading"
      v-model="inventario"
      :idm="session.terminal"
    />

    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-toolbar-title> </v-toolbar-title>
        <h4>{{ terminal.strNombre }}</h4>
        <v-spacer></v-spacer>
        {{ session.tipo.toUpperCase() }}
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
        <v-btn icon @click="abrirSeleccion">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col v-if="session.version == 'desktop'" md="10">
            <OrdenCarousel
              @seleccionar="seleccionarOrden"
              @mostrarordenes="mostrarOrdenes()"
              :ordenes="ordenes"
              :meseros="meseros"
            />
          </v-col>
          <v-col>
            <Opciones
              @ordenes="mostrarOrdenes()"
              @mobile="mobile"
              @desktop="desktop"
              @onlistaimpresion="abrirModalListadoImpresion()"
              @nuevo="nuevoPOSEnc"
            />
          </v-col>
        </v-row>
        <v-row v-if="session.version == 'mobile'">
          <v-col>
            <TipoPago
              :tipos-venta="tipoventas"
              :mesas="mesas"
              :meseros="meseros"
              :colonias="colonias"
              :clientes="clientes"
              :repartidores="repartidores"
              :tipodomicilio="version_domicilio"
              v-model="tipo"
              :orden="POSEnc"
              :POSInfo="POSInfo"
              :disabled="POSEnc.intPOSEnc != 0"
              v-show="
                POSEnc.intPOSEnc == 0 ||
                POSEnc.intPOSTipoServicio == 2 ||
                POSEnc.intPOSTipoServicio == 1 ||
                POSEnc.intPOSTipoServicio == 3
              "
              :saleRepa="setDomicilio"
            />
          </v-col>
        </v-row>
        <v-row v-if="session.version == 'mobile'">
          <v-col>
            <BusquedaArticulo
              :version="session.version"
              :articulo_especial="articulo_especial"
              :bebida_especial="bebida_especial"
              :codigo_barra="codigo_barra"
              :key="busqueda_key"
              v-if="
                ((tipo.tipo != '' && procesoPago != 2) ||
                  (tipo.tipo != '' && preparacion.valor)) &&
                validacion_tipo_venta()
              "
              :grupos="grupos"
              :articulos="articulos"
              @selectedarticulo="agregarArticulo"
              @barcode="agregarArticuloBarcode"
              @especial="agregarArticuloEspecial"
            />
          </v-col>
        </v-row>
        <v-row v-if="session.version == 'mobile'">
          <v-col>
            <Orden
              :version="session.version"
              v-model="POSEnc"
              :key="ordenDetalle.key"
              :proceso="procesoPago"
              :configuracion3="preparacion"
              @imprimir="printEncabezado"
              @imprimirdetalle="imprimirDetalle"
              @opciondetalle="opcionDetalle"
              @opcionorden="opcionOrden"
              @preparar="prepararOrden"
              @prepararpagar="prepararPagarOrden"
              @pagar="pagarOrden"
              @agregar="agregarArticuloOrden"
             
            />
          </v-col>
        </v-row>

        <v-row v-if="session.version == 'desktop'">
          <v-col md="3">
            <TipoPago
              :tipos-venta="tipoventas"
              :mesas="mesas"
              :orden="POSEnc"
              :repartidores="repartidores"
              :colonias="colonias"
              :clientes="clientes"
              :tipodomicilio="version_domicilio"
              :meseros="meseros"
              v-model="tipo"
              :impresoras="impresoras"
              :POSInfo="POSInfo"
              :disabled="POSEnc.intPOSEnc != 0"
              v-show="
                POSEnc.intPOSEnc == 0 ||
                POSEnc.intPOSTipoServicio == 2 ||
                POSEnc.intPOSTipoServicio == 1 ||
                POSEnc.intPOSTipoServicio == 3
              "
              @saleRepa="setDomicilio"
            />
          </v-col>
          <v-col md="6">
            <BusquedaArticulo
              :articulo_especial="articulo_especial"
              :bebida_especial="bebida_especial"
              :codigo_barra="codigo_barra"
              :key="busqueda_key"
              v-if="
                ((tipo.tipo != '' && procesoPago != 2) ||
                  (tipo.tipo != '' && preparacion.valor)) &&
                validacion_tipo_venta()
              "
              :grupos="grupos"
              :articulos="articulos"
              @selectedarticulo="agregarArticulo"
              @barcode="agregarArticuloBarcode"
              @especial="agregarArticuloEspecial"
            />
          </v-col>
          <v-col md="3">
            <Orden
              v-model="POSEnc"
              :key="ordenDetalle.key"
              :proceso="procesoPago"
              :configuracion3="preparacion"
              @imprimir="printEncabezado"
              @imprimirdetalle="imprimirDetalle"
              @opciondetalle="opcionDetalle"
              @opcionorden="opcionOrden"
              @preparar="prepararOrden"
              @prepararpagar="prepararPagarOrden"
              @pagar="pagarOrden"
              @agregar="agregarArticuloOrden"
             
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
//* Librerias externas
//var math = require("mathjs");
var numeral = require("numeral");
var math = require("mathjs");

import Cookies from "js-cookie";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function getChild(father, configuraciones) {
  return configuraciones
    .filter(
      (item) => item.intArticuloConfiguracionPadre == father.intArticuloConfiguracion
    )
    .sort((a, b) => (a.intOrden > b.intOrden ? 1 : -1));
}

//*Entidades y controladores
import {
  POSEnc,
  POSDet,
  POSEncPago,
  POSEncFunctions,
  POSRegister,
  POSRegisterFunctions,
  POSInfo,
  POSHerencia,
} from "../controllers/POSController.js";
import { POSUserFunctions } from "../controllers/UserController.js";
import {
  Data,
  Dialog,
  OrdenDetalle,
  MesaPago,
  LlevarPago,
  Confirm,
  CancelarDet,
  CancelarEnc,
  DescuentoDet,
  DescuentoEnc,
  JuntarCuenta,
  SepararCuenta,
  DomicilioPago,
  Toast,
  ErrorWeb,
  Session,
  Cortesia,
  Usuario,
} from "../controllers/DataController.js";
import { Configuracion } from "../controllers/Configuration.js";
import { guid } from "../js/utilities";

//* Componentes
import Loader from "../components/Loader.vue";
import OrdenCarousel from "../components/OrdenCarouselComponent.vue";
import TipoPago from "../components/TipoPagoComponent.vue";
import BusquedaArticulo from "../components/BusquedaArticuloComponent.vue";
import Orden from "../components/OrdenDetalleComponent.vue";
import Opciones from "../components/OpcionesComponent.vue";
import ConfirmDialog from "../components/ConfirmComponent.vue";
import ModalDetalleEdicion from "../components/ModalDetalleEdicion.vue";
import ModalDetalleOpciones from "../components/ModalDetalleOpciones.vue";
import ModalOrdenes from "../components/ModalOrdenesComponent.vue";
import ModalOrdenOpcion from "../components/ModalOrdenOpcion.vue";
import ModalPago from "../components/ModalPagoComponent.vue";
import ArticuloAgregar from "../components/ArticuloAgregarComponent.vue";
import DescuentoDetalle from "../components/DescuentoDetalleComponent.vue";
import CancelarDevolver from "../components/CancelarDevolverComponent.vue";
import CancelarDetalle from "../components/CancelarDetalleComponent.vue";
import CancelarEncUI from "../components/CancelarEncComponent.vue";
import Validate from "../components/ValidateComponent.vue";
import PrinterDialog from "../components/PrinterDialogComponent.vue";
import RegistroInventario from "../components/RegistroInventarioComponent.vue";
import ArticuloConfiguracion from "../components/ArticuloConfiguracionComponent.vue";
import Autorizacion from "../components/AutorizacionComponent.vue";
import DomicilioCambio from "../components/DomicilioCambioComponent.vue";
import ArticuloAgregarEspecial from "../components/ArticuloAgregarEspecialComponent.vue";
import SepararCuentaUI from "../components/SepararCuentaComponent.vue";
import CortesiaUI from "../components/CortesiaComponent.vue";
import ListadoImpresion from "../components/ListadoImpresionComponent.vue";
import FirmaAutorizacion from "../components/FirmaAutorizacionComponent.vue";
import DescuentoGeneral from "../components/DescuentoGeneralComponent.vue";
import GoogleDistance from "../components/GoogleDistance.vue";
import { db } from '../db.js'

export default {
  data() {
    return {
      terminal: {
        strNombre: "",
      },
      loading: false,
      modalAgregar: {
        visible: false,
      },
      modalAgregarEspecial: {
        visible: false,
      },
      servicio_domicilio_pago: {
        visible: false,
        total: 0,
        pagacon: 0,
        cambio: 0,
      },
      colonias: [],
      clientes: [],
      tootleEnvio:null,
      googleDistance: {
        visible: false,
        start: this.$config.google_start,
        end: "",
      },
      auth: {
        visible: false,
        strUsuario: "",
        strPassword: "",
        event: "",
      },
      autoriza: {
        visible: false,
        strUsuario: "",
        strPassword: "",
        event: "",
      },
      busqueda_key: guid(),
      configurationKey: guid(),
      configurationArticulo: {
        visible: false,
        steps: 0,
        configuration: null,
      },
      domicilioPago: new DomicilioPago(),
      repartidores: [],
      acciones: this.$config.acciones,
      pagos: [],
      meseros: [],
      terminalespago: [],
      motivosDevolucion: [],
      motivosDescuento: [],
      grupos: [],
      articulos: [],
      articulos_db: [],
      articulo_configuraciones: [],
      mesas: [],
      tiposPago: [],
      tipoventas: [],
      data: [],
      ordenes: [],
      configuraciones: this.$config.opciones,
      impresoras: [],
      dataController: new Data(),
      userFunctions: new POSUserFunctions(),
      registerFunctions: new POSRegisterFunctions(),
      ordenFunctions: new POSEncFunctions(),
      session: new Session(),
      POSEnc: new POSEnc(),
      POSInfo: new POSInfo(),
      POSRegister: new POSRegister(),
      ordenDetalle: new OrdenDetalle(),
      descuentoDet: new DescuentoDet(),
      descuentoEnc: new DescuentoEnc(),
      cancelarDet: new CancelarDet(),
      cancelarEnc: new CancelarEnc(),
      juntar_cuenta: new JuntarCuenta(),
      separar_cuenta: new SepararCuenta(),
      cortesia: new Cortesia(),
      confirm: new Confirm(),
      detalleEdicion: new Dialog(0, 0, "", "", ""),
      opcionesDetalle: new Dialog(0, 0, "", "", ""),
      cancelarDevolverVisible: new Dialog(0, 0, "", "", ""),
      facturacion: new Dialog(0, 0, "", "", ""),
      listadoImpresion: new Dialog(1, 0, "", "", ""),
      inventario: new Dialog(0, 0, "", "", ""),
      printer: new Dialog(0, 0, "", "", ""),
      // corte_max: new Configuracion(2, "IMPORTE MAXIMO DE EFECTIVO EN CAJA", 3000, false),
      preparacion: new Configuracion(
        3,
        "SE OMITE EL PROCESO DE PREPARACION",
        false,
        false
      ),
      // comandero_conf: new Configuracion(4, "EL COMANDERO NO PUEDE PAGAR UNA ORDEN", false, false),
      articulo_especial: new Configuracion(5, "ARTICULO ESPECIAL", 0, false),
      bebida_especial: new Configuracion(13, "BEBIDA ESPECIAL", 0, false),
      codigo_barra: new Configuracion(14, "CODIGO BARRA", 0, false),
      envio_ticket: new Configuracion(15, "ENVIO TICKET CORREO ELECTRONICO", 0, false),
      version_domicilio: new Configuracion(16, "VERSION DOMICILIO", 0, false),
      modalOrdenOpcionVisible: false,
      modalOrdenes: false,
      errorLogin: false,
      activeOpciones: false,
      disablePOSEnc: false,
      disableTipoPago: false,
      disableMesa: false,
      max_arqueo: false,
      isMobile: false,
      active: "guide",
      usuario: "",
      tipo: {
        tipo: "",
        mesapago: new MesaPago(),
        domicilio: new DomicilioPago(),
        claveLlevar: new LlevarPago(),
        reservacion: {
          strApellidoReferencia: "",
          strHabitacion: "",
          intHotReservacion: 0,
        },
      },
      articulo: {},
      detalleSeleccionado: {},
      empresa: 3,
      intPOSRegister: 0,
      procesoPago: 1,
      dataEmpresa: null,
      dataMesero: null,
      detalleImpresion: null,
      modalpago: {
        visible: false,
      },
    };
  },
  components: {
    GoogleDistance,
    OrdenCarousel,
    TipoPago,
    Loader,
    BusquedaArticulo,
    Orden,
    Opciones,
    ConfirmDialog,
    ModalDetalleEdicion,
    ModalDetalleOpciones,
    ModalOrdenes,
    ModalOrdenOpcion,
    ModalPago,
    ArticuloAgregar,
    DescuentoDetalle,
    DescuentoGeneral,
    CancelarDevolver,
    CancelarDetalle,
    CancelarEncUI,
    Validate,
    PrinterDialog,
    RegistroInventario,
    ArticuloConfiguracion,
    Autorizacion,
    DomicilioCambio,
    ArticuloAgregarEspecial,
    SepararCuentaUI,
    CortesiaUI,
    ListadoImpresion,
    FirmaAutorizacion,
  },
  watch: {
    ["tipo.tipo"](newValue) {
      if (newValue == "mesa") {
        this.POSEnc.strTipoServicio = "1";
        this.POSEnc.intPOSTipoServicio = 1;
      } else if (newValue == "domicilio") {
        this.POSEnc.strTipoServicio = "2";
        this.POSEnc.intPOSTipoServicio = 2;
        if( this.envio_default.valor)
         this.POSEnc.dblEnvio1=0;
          // if(this.POSEnc.intPOSEnc == 0){
          //   this.POSEnc.dblEnvio1=this.tootleEnvio.data.DblVenta;
          //   const posdet = new POSDet();
          //   posdet.intArticulo =this.tootleEnvio.data.IntArticulo;
          //   posdet.strNombre = this.tootleEnvio.data.StrNombre;
          //   posdet.strNombreCorto = this.tootleEnvio.data.StrNombreCorto;
          //   posdet.strFamilia ="ENVIOS";
          //   posdet.dblPU = this.tootleEnvio.data.DblVenta;
          //   posdet.dblPorcentajeIVA = 0;
          //   posdet.dblPorcentajeISH = 0; 
          //   posdet.dblCantidad = 1;
          //   posdet.dblTotal = posdet.dblPU * posdet.dblCantidad;
          //   posdet.intPOSRegister = this.POSEnc.intPOSRegister;
          //   this.POSEnc.posDet.push(posdet);
          // }
      } else if (newValue == "llevar") {
        this.POSEnc.strTipoServicio = "3";
        this.POSEnc.intPOSTipoServicio = 3;
      }

      if (newValue != "domicilio") {
        this.domicilioPago = new DomicilioPago();
        this.POSEnc.dblEnvio1=0;
      } 
      // if (newValue != "domicilio") {
      //   this.domicilioPago = new DomicilioPago();
      //   this.POSEnc.dblEnvio1=0;
      // } 
      else if (newValue != "mesa") {
        this.POSEnc.strMesa = "";
        this.POSEnc.intPOSMesa = 0;
      } 
      // else if (newValue != "domicilio") {
      //   this.domicilioPago = new DomicilioPago();
      //   this.POSEnc.dblEnvio1=0;
      // } 
      else if (newValue != "llevar") {
        this.articulos = this.data.length == 0 ? this.articulos_db : this.data[0];
        this.tipo.claveLlevar.tipo = 0;
        this.tipo.claveLlevar.clave = "";
      } 
      // else if (newValue != "domicilio") {
      //   this.domicilioPago = new DomicilioPago();
      //   this.POSEnc.dblEnvio1=0;
      // } 
      else if (newValue == null) this.POSEnc.strTipoServicio = null;

      this.cambiar_precio();
      this.busqueda_key = guid();
    },
    async ["POSEnc.intHotReservacion"](newValue) {
      if (newValue && this.POSEnc.intPOSEnc > 0) {
        if (newValue > 0) {
          try {
            this.loading = true;
            this.tipo.reservacion = await this.ordenFunctions.get_reservacion(newValue);
          } catch (e) {
            this.$toast.error(
              "No se pudo cargar los datos de la reservacion. ID: " + newValue,
              new Toast()
            );
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.tipo.reservacion.intHotReservacion = 0;
        this.tipo.reservacion.strApellidoReferencia = "";
        this.tipo.reservacion.strHabitacion = "";
      }
    },
    async ["tipo.claveLlevar.tipo"](newValue) {
      this.loading = true;
      try {
        if (newValue == 0) {
          this.articulos = this.data.length == 0 ? this.articulos_db : this.data[0];
          this.articulo_configuraciones = this.data[4];
        } else {
          this.articulos = await this.dataController.loadPreciosTipoVenta(newValue);
          this.articulo_configuraciones = await this.dataController.loadPreciosArticuloConfigutacionTipoVenta(newValue);
        }

        this.cambiar_precio();
        this.busqueda_key = guid();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  methods: {
    cambiar_precio() {
      if (this.POSEnc.intPOSEnc == 0) {
        this.POSEnc.posDet.forEach((detalle) => {
          if (this.data.length == 0) {
            
            let articulo = this.articulos.find((x) => x.IntArticulo == detalle.intArticulo);
            if (articulo) {
              console.log('cambiar_precio()')
              console.log('cambiar_precio() ' + articulo);
              detalle.dblPU = articulo.DblPU;
              detalle.dblPorcentajeIVA = articulo.DblPorcentajeIVA;
              detalle.dblPorcentajeISH = articulo.DblPorcentajeISH;
              detalle.dblCantidad = detalle.cantidad;
              detalle.dblTotal = detalle.dblPU * detalle.dblCantidad;
            }
          }else {
            let articulo = this.articulos.find((x) => x.intArticulo == detalle.intArticulo);
            if (articulo) {
              console.log('cambiar_precio()')
              console.log('cambiar_precio() ' + articulo);
              detalle.dblPU = articulo.dblPU;
              detalle.dblPorcentajeIVA = articulo.dblPorcentajeIVA;
              detalle.dblPorcentajeISH = articulo.dblPorcentajeISH;
              detalle.dblCantidad = detalle.cantidad;
              detalle.dblTotal = detalle.dblPU * detalle.dblCantidad;
            }
          }
        });
      }
    },
    validacion_tipo_venta() {
      if (this.tipo.tipo != "llevar") return true;
      else if (this.tipo.claveLlevar.tipo != 0) return true;
      else if (this.tipo.claveLlevar.tipo == 0) return true;
      else return false;
    },
    saveConfiguration(configuration) {
//        if (this.detalleSeleccionado.posDetArticuloConfiguracion!= undefined){
//         this.detalleSeleccionado.posDetArticuloConfiguracion.push(configuration);
//         this.posenc.posdet.posDetArticuloConfiguracion = configuration;
// console.log(this.posenc.posdet.posDetArticuloConfiguracion)
//        }
//        else 
      //  {

       
      //  this.detalleSeleccionado.posDetArticuloConfiguracion==undefined;

      console.log(configuration)
      // console.log(this.detalleSeleccionado.posDetArticuloConfiguracion)

      let configuration_price = configuration.reduce((a, b) => {
        return a + parseFloat(b.dblPrecio);
      }, 0);
      const posdet = new POSDet();
      posdet.intArticulo = this.articulo.intArticulo;
      posdet.strNombre = this.articulo.strNombre;
      posdet.strNombreCorto = this.articulo.strNombreCorto;
      posdet.strFamilia = this.articulo.strFamilia;
      posdet.dblPU = this.articulo.dblPU + configuration_price;
      posdet.dblPorcentajeIVA = this.articulo.dblPorcentajeIVA;
      posdet.dblPorcentajeISH = this.articulo.dblPorcentajeISH;
      posdet.intPOSImpresora = this.articulo.intPOSImpresora;
      posdet.dblCantidad = 1;
      posdet.dblTotal = posdet.dblPU;
      posdet.intPOSRegister = this.POSEnc.intPOSRegister;
      posdet.posDetArticuloConfiguracion = configuration;
      this.POSEnc.posDet.push(posdet);
      this.limpiarArticulo();
      // this.configurationArticulo.configuration.forEach((x) => {
      //   x.color = "#ffffff";
      // });
       
    },
    desktop() {
      this.session = new Session();
    },
    mobile() {
      this.session = new Session();
    },
    imprimirDetalle(detalle) {
      this.printer.visible = true;
      this.printer.evento = "det";
      this.detalleImpresion = detalle;
    },
    mandarImpresionDetalle() {
      this.printer.evento = "";
      this.printer.visible = false;
    },
    async mandarImpresionEncabezado(printer) {
      this.printer.evento = "";
      this.printer.visible = false;
      if (printer == "-1") {
        await this.printPOS(this.POSEnc);
      }
    },
    abrirModalListadoImpresion() {
      this.listadoImpresion.visible = true;
    },
    nuevoPOSEnc() {
      this.POSEnc = new POSEnc();
      this.POSEnc.intPOSRegister = this.intPOSRegister;
      this.POSEnc.strUsuarioPOS = this.session.usuario;
      this.tipo.tipo = "";
      this.disablePOSEnc = false;
      //CONFIGURACION 3
      if (this.preparacion.valor) {
        this.procesoPago = 2;
        if (this.session.tipo.toUpperCase() == "COMANDERO" && this.comandero_conf.valor) {
          this.procesoPago = 1;
        }
      } else {
        this.procesoPago = 1;
      }

      //Limpiamos mesa
      this.tipo.reservacion.intHotReservacion = 0;
      this.tipo.reservacion.strHabitacion = "";
      this.tipo.reservacion.strApellidoReferencia = "";

      this.tipo.mesapago.mesa = 0;
      this.tipo.mesapago.mesero = 0;
      this.tipo.claveLlevar.tipo = 0;
      this.tipo.claveLlevar.clave = "";
      this.domicilioPago = new DomicilioPago();
      this.disableMesa = false;
    },
    eliminarDetalleValido() {
      this.confirm.visible = false;
      if (this.detalleSeleccionado.intPOSDet == 0) {
        this.POSEnc.posDet.splice(this.detalleSeleccionado.index, 1);
        this.$toast.success("Registro eliminado con exito", new Toast());
      } else {
        this.$toast.error(
          "No se puede eliminar un detalle guardado, intente cancelando",
          new Toast()
        );
      }
    },
    mostrarOrdenes() {
      this.modalOrdenes = true;
    },
    seleccionarOrden(orden) {
      //Cerrar el modal de maximizacion, tiene un bug, lo checamos despues
      this.modalOrdenes = false;
      this.POSEnc = { ...orden };

      if (this.POSEnc.intPOSTipoServicio == 1) this.tipo.tipo = "mesa";
      else if (this.POSEnc.intPOSTipoServicio == 2) this.tipo.tipo = "domicilio";
      else if (this.POSEnc.intPOSTipoServicio == 3) this.tipo.tipo = "llevar";

      //Si es mesa
      this.disableMesa = false;
      if (this.tipo.tipo == "mesa") {
        this.tipo.mesapago.mesa = parseInt(orden.intPOSMesa);
        this.tipo.mesapago.mesero = parseInt(orden.intPOSMesero);
        this.disableMesa = true;
      }
      this.disablePOSEnc = true;
      this.procesoPago = 2;
      if (this.tipo.tipo == "llevar") {
        this.tipo.claveLlevar.tipo = this.POSEnc.intPOSTipoVenta;
        this.tipo.claveLlevar.clave = this.POSEnc.strMesa;
      }

      if (this.tipo.tipo == "domicilio") {
        console.log(this.POSEnc.intCliente)
        this.domicilioPago = {
          intCliente: this.POSEnc.intCliente,
          strTelefono: this.POSEnc.strTelefono,
          strNombre: this.POSEnc.strNombre,
          strDireccion: this.POSEnc.strDireccion,
          strColonia: this.POSEnc.strColonia,
          strEntreCalle: this.POSEnc.strEntreCalle,
          strReferencia: this.POSEnc.strReferencia,
          strPoblacion: this.POSEnc.strPoblacion,
          strTelefono2: this.POSEnc.strTelefono2,
          intEmpleado: this.POSEnc.intEmpleado,
        };
      }
      this.ordenDetalle.key = guid();
    },
    abrirModal: function (modal) {
      this.$modal.show(modal);
    },
    cerrarModal: function (modal) {
      this.$modal.hide(modal);
    },
    agregarArticuloEspecial(version) {
      this.modalAgregarEspecial.version = version;
      this.modalAgregarEspecial.visible = true;
    },
    guardarArticuloEspecial(articulo) {
      const posdet = new POSDet();
      if (articulo.version == "bebida") {
        posdet.intArticulo = this.articulo_especial.valor;
        posdet.strNombreCorto = "BEBIDA_ESPECIAL";
        posdet.strFamilia = "BEBIDA ESPECIAL";
      } else {
        posdet.intArticulo = this.articulo_especial.valor;
        posdet.strNombreCorto = "ARTICULO_ESPECIAL";
        posdet.strFamilia = "ARTICULO ESPECIAL";
      }
      posdet.strNombre = articulo.strDescripcion;
      posdet.strNombreCorto = articulo.strNombreCorto;
      posdet.dblPU = articulo.dblPrecio;
      posdet.dblPorcentajeIVA = 0;
      posdet.dblPorcentajeISH = 0;
      posdet.dblCantidad = articulo.dblCantidad;
      posdet.dblTotal = posdet.dblPU * posdet.dblCantidad;
      posdet.intPOSRegister = this.POSEnc.intPOSRegister;
      posdet.intPOSImpresora =articulo.intPOSImpresora;

      this.POSEnc.posDet.push(posdet);
      this.limpiarArticulo();
      this.modalAgregar.visible = false;
    },
    agregarArticulo: function (articulo) {
      this.articulo = articulo;
      
      if(this.articulo.dblPU == 0){
        this.$toast.error("Articulo no disponible en el Menu", new Toast());
        return;
      }
      let articulosConfiguraciones = [];
      this.articulo_configuraciones.forEach((x) => {
        articulosConfiguraciones.push(x);
      });
      let configuracion_padre = articulosConfiguraciones.find(
        (x) => x.intArticuloConfiguracion == this.articulo.intArticuloConfiguracion
      );
      if (configuracion_padre != null) {
        let configuraciones = articulosConfiguraciones.filter((x) => {
          if (
            x.intArticuloConfiguracionMaster ==
            configuracion_padre.intArticuloConfiguracion
          ) {
            return x;
          }
        });

        //* Buscamos primero la configuracion padre
        let arbolConfiguration = configuraciones
          .filter(
            (x) =>
              x.intArticuloConfiguracion == configuracion_padre.intArticuloConfiguracion
          )
          .sort((a, b) => (a.intOrden > b.intOrden ? 1 : -1));
        //* Llenamos los hijos
        arbolConfiguration.forEach((padre) => {
          padre.padre = true;
          padre.hijos = getChild(padre, configuraciones);
          padre.selected = false;
          padre.select = false;
          padre.intCantidad=0;
          padre.color = "#ffffff";
          padre.hijos.forEach((hijo) => {
            hijo.hijos = getChild(hijo, configuraciones);
            hijo.selected = false;
            hijo.intCantidad=0;
            hijo.color = "#ffffff";

            hijo.hijos.forEach((nieto) => {
              nieto.hijos = getChild(nieto, configuraciones);
              nieto.selected = false;
              nieto.select = false;
              nieto.intCantidad=0;
              nieto.color = "#ffffff";
              if (nieto.hijos) {
                nieto.hijos.forEach((bisnieto) => {
                  bisnieto.selected = false;
                  bisnieto.select = false;
                  bisnieto.intCantidad=0;
                  bisnieto.color = "#ffffff";
                });
              }
            });
          });
        });
        arbolConfiguration.sort((a, b) => (a.intOrden > b.intOrden ? 1 : -1));
        this.configurationArticulo.configuration = arbolConfiguration;
        this.configurationArticulo.steps = arbolConfiguration.length;
        this.configurationArticulo.visible = true;
        this.configurationKey = guid();
        
      } else {
        this.modalAgregar.visible = true;
      }
    },
    agregarArticuloBarcode: function (articulo) {
      this.articulo = articulo;
      articulo.precio = articulo.dblPU;
      articulo.cantidad = 1;
      this.articuloValidado(articulo);
    },
    limpiarArticulo() {
      this.articulo = {};
    },
    articuloValidado: function (articulo) {
      const posdet = new POSDet();
      console.log(articulo.intPOSImpresora)
      posdet.intArticulo = articulo.intArticulo;
      posdet.strNombre = articulo.strNombre;
      posdet.strNombreCorto = articulo.strNombreCorto;
      posdet.strFamilia = articulo.strFamilia;
      posdet.dblPU = articulo.precio;
      posdet.dblPorcentajeIVA = articulo.dblPorcentajeIVA;
      posdet.dblPorcentajeISH = articulo.dblPorcentajeISH;
      posdet.dblCantidad = articulo.cantidad;
      posdet.dblTotal = posdet.dblPU * posdet.dblCantidad;
      posdet.intPOSRegister = this.POSEnc.intPOSRegister;
      posdet.intPOSImpresora = articulo.intPOSImpresora;
      this.POSEnc.posDet.push(posdet);
      this.limpiarArticulo();
      this.modalAgregar.visible = false;
    },
    opcionOrden: function () {
      this.modalOrdenOpcionVisible = true;
    },

    async autorizarFirma(event) {
      this.autoriza.visible = false;
      this.loading = true;
      try {
        if (event == "imprimir") {
          this.printer.visible = true;
          this.printer.event = "preparacion";
          this.printer.visible = true;
          this.POSEnc.POSInfo = this.POSInfo;
          this.POSEnc.StrTerminal = this.terminal.strNombre;
          this.POSEnc.datFecha = this.convertDateJSON(this.POSEnc);
          this.printer.printable = this.POSEnc;
        }
        if (event == "adminsitrador") {
          this.$router.push("/AdministradorTicket");
        }
        if (event == "reimpresion") {
          this.$router.push("/Reimpresion");
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async cancelarOrden() {
      this.cancelarEnc.visible = false;
      this.loading = true;
      try {
        //Validamos que la autorizacion es correcta
        let usuario = new Usuario(this.$config.api_backend_route);
        usuario.usuario = this.cancelarEnc.usuario;
        usuario.password = this.cancelarEnc.password;
        await usuario.validate_cancelacion();
        //Cambiamos encabezado
        this.POSEnc.intEstatus = 9;
        this.POSEnc.strComentarioCancelacion = this.cancelarEnc.comentario;
        this.POSEnc.strUsuarioCancelacion = this.cancelarEnc.usuario;
        this.POSEnc.intPOSMotivoCancelacion = this.cancelarEnc.motivo;
        this.POSEnc.intCancelacionDevolucion = 9;
        //Agregamos la cancelacion al detalle
        await this.ordenFunctions.cancelar(this.POSEnc);
        //Se quita orden cancelada
        this.ordenes = this.ordenes.filter((orden) => {
          if (orden.intPOSEnc != this.POSEnc.intPOSEnc) {
            return orden;
          }
        });

        this.nuevoPOSEnc();
        this.cancelarEnc.usuario = "";
        this.cancelarEnc.comentario = "";
        this.cancelarEnc.password = "";
        this.cancelarEnc.motivo = 0;
        this.$toast.success("Orden cancelada con exito", new Toast());
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    cancelarPOSEnc: async function () {
      this.loading = true;
      try {
        this.modalOrdenOpcionVisible = false;
        const detallesCancelados = this.POSEnc.posDet.filter(
          (detalle) => detalle.intEstatus == 8 || detalle.intEstatus == 9
        );
        if (detallesCancelados.length != this.POSEnc.posDet.length)
          throw "No se puede cancelar orden, tiene que cancelarse todos los articulos primero";

        this.cancelarEnc.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    opcionDetalle: function (detalle) {
      this.opcionesDetalle.visible = true;
      this.detalleSeleccionado = detalle;
    },
    editarDetalle() {
      this.opcionesDetalle.visible = false;
      if (this.detalleSeleccionado.intPOSDet == 0) {
        this.detalleEdicion.visible = true;
      } else {
        this.$toast.error(
          "No se puede editar un producto que ya se mando a preparar, puede agregar uno nuevo o cancelar este producto",
          new Toast()
        );
      }
    },
    reconfigurarDetalle() {
      this.opcionesDetalle.visible = false;
      console.log(this.detalleSeleccionado.intPOSDet )
      console.log(this.detalleSeleccionado.posDetArticuloConfiguracion )
      if (this.detalleSeleccionado.posDetArticuloConfiguracion != undefined)
       {
        this.configurationArticulo.visible = true;
        
      } else {
        this.$toast.error(
          "No se puede editar un producto que ya se mando a preparar, puede agregar uno nuevo o cancelar este producto",
          new Toast()
        );
      }
    },
    descuentoDetalle() {
      this.opcionesDetalle.visible = false;
      if (this.POSEnc.intPOSEnc > 0) {
        if (this.detalleSeleccionado.dblPorcentajeDescuento == 0) {
          this.descuentoDet.posdet = this.detalleSeleccionado;
          this.descuentoDet.isVisible = true;
        } else {
          this.$toast.error(
            "No se puede agregar descuento al producto, ya tiene " +
              this.detalleSeleccionado.dblPorcentajeDescuento +
              "% aplicado",
            new Toast()
          );
        }
      } else {
        this.$toast.error(
          "La orden debe estar en preparacion para aplicar descuento",
          new Toast()
        );
      }
    },
    cancelarDetalle() {
      this.opcionesDetalle.visible = false;
      if (this.POSEnc.intPOSEnc > 0) {
        this.cancelarDet.posdet = this.detalleSeleccionado;
        this.cancelarDet.isVisible = true;
      } else {
        this.$toast.error(
          "La orden debe estar en preparacion para cancelarla",
          new Toast()
        );
      }
    },
    eliminarDetalle() {
      this.opcionesDetalle.visible = false;
      this.confirm.title = "Eliminacion";
      this.confirm.text = "¿Desea eliminar este articulo?";
      this.confirm.visible = true;
      this.confirm.event = "eliminar-detalle";
    },
    separado(payload) {
      this.ordenes.push(payload.nuevo);
      let index_original = this.ordenes.findIndex(
        (orden) => orden.intPOSEnc == payload.original.intPOSEnc
      );
      this.ordenes[index_original] = payload.original;
    },
    async prepararOrden() {
      this.loading = true;
      try {
        this.POSEnc.intPOSRegister = this.session.register;
        this.POSEnc.strUsuarioPOS = this.session.usuario;
        this.POSEnc.strUsuarioAlta = this.session.usuario;

        if (this.tipo.tipo == "mesa") {
          this.POSEnc.intPOSTipoServicio = 1;
          this.POSEnc.intPOSMesa = this.tipo.mesapago.mesa;
          this.POSEnc.intPOSMesero = this.tipo.mesapago.mesero;
        } else if (this.tipo.tipo == "domicilio") {
          this.POSEnc.intPOSTipoServicio = 2;
        } else if (this.tipo.tipo == "llevar") {
          this.POSEnc.intPOSTipoServicio = 3;
          this.POSEnc.strMesa = this.tipo.claveLlevar.clave;
          this.POSEnc.intPOSTipoVenta = this.tipo.claveLlevar.tipo;
        } else {
          this.POSEnc.intPOSTipoServicio = 0;
        }

        if (this.POSEnc.intPOSTipoServicio == null || this.POSEnc.intPOSTipoServicio == 0)
          throw "Favor de seleccionar un tipo de servicio";
        if (this.POSEnc.intPOSTipoServicio == 1 && this.tipo.mesapago.mesa == 0)
          throw "Favor de seleccionar una mesa";
        if (this.POSEnc.intPOSTipoServicio == 1 && this.tipo.mesapago.mesero == 0)
          throw "Favor de seleccionar un mesero";
        if (this.POSEnc.posDet.length == 0)
          throw "Favor agregar por lo menos un articulo";
        if (this.POSEnc.intPOSTipoServicio == 3 && this.tipo.claveLlevar.clave == "")
          throw "Favor agregar una clave";
        if (this.POSEnc.intPOSTipoServicio == 2 && this.tipo.domicilio.intCliente == 0)
          throw "Favor de seleccionar un cliente, o guardar la informacion del cliente";

        // ** SE NECESITA AGREGAR LA CANTIDAD CON LA QUE SE VA A PAGAR
        // ** Agregamos una nueva opcion de GoogleMaps
        if (this.POSEnc.intPOSTipoServicio == 2) {
          if (this.$config.envio_google) {
            this.googleDistance.end =
              this.tipo.domicilio.strDireccion.toUpperCase() +
              " " +
              this.tipo.domicilio.strColonia.toUpperCase() +
              " " +
              this.tipo.domicilio.strPoblacion.toUpperCase();
            this.googleDistance.visible = true;
            return;
          } else {
            this.servicio_domicilio_pago.visible = true;
            this.servicio_domicilio_pago.total = this.POSEnc.posDet.reduce((a, b) => {
              let iva = math
                .chain(b.dblPU)
                .multiply(b.dblCantidad)
                .multiply(b.dblPorcentajeIVA)
                .divide(100)
                .done();

                let ish = math
                .chain(b.dblPU)
                .multiply(b.dblCantidad)
                .multiply(b.dblPorcentajeISH)
                .divide(100)
                .done();

              let precio = math.chain(b.dblPU).multiply(b.dblCantidad).done();
              let x = math.chain(iva).add(ish).add(precio).done();
              let descuento =0.0;
              if(b.intTipoDescuento == 1){
                descuento = math
                .chain(x)
                .multiply(b.dblPorcentajeDescuento)
                .divide(100)
                .done();
              }else if (b.intTipoDescuento == 2){
                descuento = math
                .chain(x)
                .subtract(b.dblPorcentajeDescuento)
                .done();
              }
              
              // let descuento = math
              //   .chain(x)
              //   .multiply(b.dblPorcentajeDescuento)
              //   .divide(100)
              //   .done();

              return math
                .chain(a)
                .add(math.round(precio, 2))
                .add(math.round(iva, 2))
                .add(math.round(ish, 2))
                .subtract(math.round(descuento, 2))
                .done();
            }, 0);

            //? Le agregamos envio al total
            if( this.envio_default.valor){
                let envio =this.$config.envio_minimo_precio;
                this.servicio_domicilio_pago.total = math
                .chain(this.servicio_domicilio_pago.total)
                .add(envio)
                .done();
            }
           


            this.servicio_domicilio_pago.pagacon = this.servicio_domicilio_pago.total;
            return;
          }
          
        }

        this.POSEnc.intEstatus = 2;
        this.POSEnc.intHotReservacion = this.tipo.reservacion.intHotReservacion;

        const POSEnc = await this.ordenFunctions.preparar(this.POSEnc);
        this.ordenes.push(POSEnc);
        this.nuevoPOSEnc();
        this.$toast.success("Orden Guardada con exito", new Toast());
        //?Checar impresion
        const config = this.configuraciones.find((c) => c.id == 1);
        if (config.valor) {
          this.printPOS(POSEnc);
          this.prepararCocina(POSEnc)
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async prepararOrdenDomicilio(response) {
      this.loading = true;
      try {
        this.POSEnc.intEstatus = 2;
        this.POSEnc.intEmpleado = this.tipo.domicilio.intEmpleado;
        this.POSEnc.intCliente = this.tipo.domicilio.intCliente;
        this.POSEnc.dblPagaCon = response.pagaCon;
        
        const POSEnc = await this.ordenFunctions.preparar(this.POSEnc);

        this.ordenes.push(POSEnc);
        this.nuevoPOSEnc();
        this.$toast.success("Orden Guardada con exito", new Toast());
        this.servicio_domicilio_pago.visible = false;
        //?Checar impresion
        const config = this.configuraciones.find((c) => c.id == 1);
        if (config.valor) {
          this.printPOS(POSEnc);
          this.prepararCocina(POSEnc)
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async prepararCocina(response) {
      this.loading = true;
      try {
                  console.log("prepararCocina")
        const POSEnc = await this.ordenFunctions.retrieve_id(response.intPOSEnc);
        //?Checar impresion
        const config = this.configuraciones.find((c) => c.id == 1);
        if (config.valor) {
          let details = [];
          let intPOSimpresora = this.impresoras.find(element => element.name == 'COCINA').intPOSImpresora;
          const detalle = await this.ordenFunctions.detalles(response.intPOSEnc);
          detalle.forEach((x) => {
            if(x.intPOSImpresora == intPOSimpresora){
              details.push(x); }
          });
          console.log(intPOSimpresora)
          console.log(details)
          console.log(details.length)
          if(details.length>0){
            console.log("entro")
            this.printCocina(POSEnc);
          }
          
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async prepararPagarOrden() {
      try {
        this.loading = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async pagarOrden() {
      //Verificamos si tiene algun pago
      this.loading = true;
      try {
        //Si es COMANDERO y con la configuracion el verdadero
        if (this.comandero_conf.valor && this.session.tipo.toUpperCase() == "COMANDERO") {
          throw "[CONF 4]. El COMANDERO no puede pagar una orden.";
        }
        //Si todos los detalles estan cancelados no se puede pagar orden
        const cancelarDetalle = this.POSEnc.posDet.filter(
          (detalle) => detalle.intEstatus == 8 || detalle.intEstatus == 9
        );
        if (cancelarDetalle.length == this.POSEnc.posDet.length)
          throw "No se puede pagar orden, tiene todos los detalles cancelados, favor de Cancelar la orden";

        if(this.POSEnc.intPOSTipoServicio == 2 && this.POSEnc.intEmpleado == null)
          throw "Error.Favor de Asignar repartidor";

        this.POSEnc.posEncPago = await this.ordenFunctions.pagos(this.POSEnc.intPOSEnc);
        this.modalpago.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
      pagarDomicilioDistancia(envio){
      this.googleDistance.visible = false;
      this.POSEnc.dblEnvio1 = envio;
      //!Se tiene que agregar un POSDet del Envio ?

      this.servicio_domicilio_pago.visible = true;
      this.servicio_domicilio_pago.total = this.POSEnc.posDet.reduce((a, b) => {
        let iva = math
          .chain(b.dblPU)
          .multiply(b.dblCantidad)
          .multiply(b.dblPorcentajeIVA)
          .divide(100)
          .done();

          let ish = math
          .chain(b.dblPU)
          .multiply(b.dblCantidad)
          .multiply(b.dblPorcentajeISH)
          .divide(100)
          .done();

        let precio = math.chain(b.dblPU).multiply(b.dblCantidad).done();
        let x = math.chain(iva).add(math.chain(ish)).add(precio).done();
        let descuento =0.0;
        
        if(b.intTipoDescuento == 1){
          descuento = math
          .chain(x)
          .multiply(b.dblPorcentajeDescuento)
          .divide(100)
          .done();
        }else if (b.intTipoDescuento == 2){
          descuento = math
          .chain(x)
          .subtract(b.dblPorcentajeDescuento)
          .done();
        }
              
        // let descuento = math
        //   .chain(x)
        //   .multiply(b.dblPorcentajeDescuento)
        //   .divide(100)
        //   .done();

        return math
          .chain(a)
          .add(math.round(precio, 2))
          .add(math.round(iva, 2))
          .add(math.round(ish, 2))
          .subtract(math.round(descuento, 2))
          .done();
      }, 0);

      // ? Le agregamos envio al total
      
          if( this.envio_default.valor){
              let envio =this.$config.envio_minimo_precio;
              this.servicio_domicilio_pago.total = math
              .chain(this.servicio_domicilio_pago.total)
              .add(envio)
              .done();
            }
     
      this.servicio_domicilio_pago.pagacon = this.servicio_domicilio_pago.total;
      return;
    },
  async  guardarCortesia() {
    
    let posenc = await this.ordenFunctions.retrieve_id(this.POSEnc.intPOSEnc);

        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.session.terminal;
        this.printer.event ="cortesia";
        this.printer.visible = true;
        this.printer.printable = posenc;

      this.ordenes = this.ordenes.filter((orden) => {
        return orden.intPOSEnc != this.POSEnc.intPOSEnc;
      });
      this.$toast.success(
        "Se ha aplicado la cortersia en la orden " + this.POSEnc.intOrden,
        new Toast()
      );

      this.nuevoPOSEnc();
    },
    async agregarArticuloOrden() {
      this.loading = true;
      try {
        const orden = await this.ordenFunctions.agregar_productos(this.POSEnc);
        this.POSEnc.posDet = orden.posDet;
        this.POSEnc.dblSubtotal = orden.dblSubtotal;
        this.POSEnc.dblIVA = orden.dblIVA;
        this.POSEnc.dblISH = orden.dblISH;
        this.POSEnc.dblTotal = orden.dblTotal;
        this.ordenes.forEach((x) => {
          if (x.intPOSEnc == this.POSEnc.intPOSEnc) {
            x.dblSubtotal = orden.dblSubtotal;
            x.dblIVA = orden.dblIVA;
            x.dblISH = orden.dblISH;
            x.dblTotal = orden.dblTotal;
            x.posDet = orden.posDet;
            //console.log("ENCONTRADO");
          }
        });
        this.procesoPago = 2;
        this.$toast.success("Articulos agregados con exito", new Toast());
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async realizarPago(obj) {
      const propina = obj.propina;
      const cambio = obj.cambio;
      const pagos = obj.pagos;
      const facturacion = obj.factura;
      this.loading = true;
      try {
        this.modalpago.visible = false;
        pagos.forEach((pago) => {
          const POSEncPagoNuevo = new POSEncPago();
          POSEncPagoNuevo.intPOSEncPago = 0;
          if (pago.tipoPago.strNombre == "04-PROSEPAGO") {
            POSEncPagoNuevo.strNombreTarjeta = pago.referencia;
          }
          POSEncPagoNuevo.strCorreoElectronico = pago.correo;
          POSEncPagoNuevo.intPOSEnc = this.POSEnc.intPOSEnc;
          POSEncPagoNuevo.intTipoPago = pago.tipoPago.intTipoPago;
          POSEncPagoNuevo.strNombre = pago.tipoPago.strNombre;
          POSEncPagoNuevo.dblImporte = pago.cantidad.toString();
          POSEncPagoNuevo.strReferencia = pago.referencia;
          POSEncPagoNuevo.intNumeroCuenta = pago.reservacion;
          POSEncPagoNuevo.intPOSTerminalPago = pago.terminal_seleccionada;
          POSEncPagoNuevo.dblCambio =
            pago.tipoPago.strNombre == "01-CASH" ||
            pago.tipoPago.strNombre == "01-EFECTIVO"
              ? cambio
              : 0.0;
          POSEncPagoNuevo.strUsuario = this.session.usuario;
          this.POSEnc.posEncPago.push(POSEncPagoNuevo);
        });
        this.POSEnc.dblPropina = propina;
        this.POSEnc.intRequiereFactura = facturacion ? 1 : 0;
        const POSEncPagado = await this.ordenFunctions.pagar(this.POSEnc,this.$config.descuento_almacen);

        let prosepago = POSEncPagado.posEncPago.find(
          (x) => x.strNombre == "04-PROSEPAGO"
        );

        var incompleto = false;
        if (prosepago) {
          for await (let pago of POSEncPagado.posEncPago) {
            console.log(pago);
            if (pago.strNombre == "04-PROSEPAGO") {
              let idTransaccion = await this.ordenFunctions.ventaProsepago(
                pago,
                POSEncPagado.intOrden
              );
              const waitSecond = 3;
              //* 10 Segundos antes de la primera llamada
              await delay(7 * 1000);

              //* Estatus 901 -> Transaccion en proceso
              //* Estatus 900 -> Transaccion incorrecta
              //* Estatus XML -> Transaccion terminada
              let prosepagoResponse = 901;
              do {
                await delay(waitSecond * 1000);
                prosepagoResponse = await this.ordenFunctions.estatusProsepago(
                  idTransaccion
                );
                console.log(prosepagoResponse);
              } while (prosepagoResponse == 901);

              if (
                prosepagoResponse.PVresultado.status == 1 ||
                prosepagoResponse.PVresultado.status == 0
              ) {
                incompleto = true;
                //Cancelar POSEncPago
                await this.ordenFunctions.eliminarPago(pago.intPOSEncPago);
                console.log(prosepagoResponse.PVresultado);
                //Fue cancelada por el usuario
                this.$toast.error(
                  "Se cancelo el pago por parte de la terminal de PROSEPAGO",
                  new Toast()
                );
                //Eliminamos el pago
              } else if (prosepagoResponse.PVresultado.status == 2) {
                console.log(prosepagoResponse.PVresultado);
                await this.ordenFunctions.saveProsepago(
                  pago.intPOSEncPago,
                  prosepagoResponse.PVresultado
                );
                //Pagada con exito
              } else if (prosepagoResponse.PVresultado.status == 8) {
                //Operacion invalida
                incompleto = true;
                await this.ordenFunctions.eliminarPago(pago.intPOSEncPago);
                this.$toast.error(
                  "Operacion invalida por parte de PROSEPAGO, no se pudo completar la transaccion",
                  new Toast()
                );
              }
            }
          }
        }

        if (incompleto) {
          this.POSEnc.intEstatus = 2;
          this.POSEnc.posEncPago = await this.ordenFunctions.pagos(this.POSEnc.intPOSEnc);
          this.$toast.error(
            "La orden no fue pagada completamente, intente de nuevo",
            new Toast()
          );
        } else {
          this.nuevoPOSEnc();
          this.$toast.success("Orden pagada con exito", new Toast());
          // ARREGLAR
          await this.printPOSPago(POSEncPagado);
          //Eliminamos la orden de la vista
          this.ordenes = this.ordenes.filter((orden) => {
            return orden.intPOSEnc != POSEncPagado.intPOSEnc;
          });
        }
        this.arqueo_automatico()
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
        this.POSEnc.intEstatus = 1;
        this.POSEnc.posEncPago = [];
      } finally {
        this.loading = false;
      }
    },
    guardarEdicionDetalle() {
      if (this.detalleSeleccionado.dblPorcentajeDescuento > 0) {
        this.detalleSeleccionado.dblTotal =
          this.detalleSeleccionado.dblPU * this.detalleSeleccionado.dblCantidad -
          this.detalleSeleccionado.dblPU *
            this.detalleSeleccionado.dblCantidad *
            (this.detalleSeleccionado.dblPorcentajeDescuento / 100);
      } else {
        this.detalleSeleccionado.dblTotal =
          this.detalleSeleccionado.dblCantidad * this.detalleSeleccionado.dblPU;
      }
      this.POSEnc.posDet[this.detalleSeleccionado.index] = this.detalleSeleccionado;

      this.detalleEdicion.visible = false;
      this.ordenDetalle.index++;
      this.ordenDetalle.key = "orden" + this.ordenDetalle.index.toString();
    },
    cancelarEdicionDetalle() {
      this.cerrarModal("modal-detalle-edicion");
    },
    toMoney(value) {
      return numeral(value).format("$0,0.00");
    },
    async guardarDescuento(POSEnc) {
      this.POSEnc.posDet.forEach((detalle) => {
        if (detalle.intPOSDet == this.detalleSeleccionado.intPOSDet) {
          detalle.strUsuarioDescuento = this.detalleSeleccionado.strUsuarioDescuento;
          detalle.strReferenciaDescuento = this.detalleSeleccionado.strReferenciaDescuento;
          detalle.strClaveDescuento = this.detalleSeleccionado.strClaveDescuento;
          detalle.intTipoDescuento = this.detalleSeleccionado.intTipoDescuento;
          detalle.intPOSDescuento = this.detalleSeleccionado.intPOSDescuento;
          detalle.intPOSMotivoDescuento = this.detalleSeleccionado.intPOSMotivoDescuento;
          detalle.dblPorcentajeDescuento = this.detalleSeleccionado.dblPorcentajeDescuento;
          detalle.dblImporteDescuento = this.detalleSeleccionado.dblImporteDescuento;
        }
      });
      this.descuentoDet.posdet = {};
      this.POSEnc.dblImporteDescuento = POSEnc.dblImporteDescuento;
      this.POSEnc.dblTotal = POSEnc.dblTotal;
      this.ordenes.forEach((x) => {
        if (x.intPOSEnc == POSEnc.intPOSEnc) {
          x.dblImporteDescuento = POSEnc.dblImporteDescuento;
          x.dblTotal = POSEnc.dblTotal;
        }
      });
      this.$toast.success("Descuento aplicado con exito", new Toast());
    },
    async guardarDescuentoEnc(posEnc) {
      this.POSEnc = posEnc;
      this.$toast.success("Descuento aplicado con exito", new Toast());
    },
    setLoading(x) {
      this.loading = x;
    },
    async guardarCancelacionDet(det) {
      console.log(det.posdet)
      console.log(det.posdet.dblTotal)
      console.log(this.POSEnc.dblTotal)
      // //*Cambiamos el detalle de la orden

      this.POSEnc.dblTotal=this.POSEnc.dblTotal-det.posdet.dblTotal;
      this.POSEnc.dblSubtotal=this.POSEnc.dblSubtotal-det.posdet.dblTotal;

      this.POSEnc.posDet.forEach((detalle) => {
        if (detalle.intPOSDet == this.detalleSeleccionado.intPOSDet) {
          detalle.strUsuarioCancelacion = this.detalleSeleccionado.strUsuarioCancelacion;
          detalle.strClaveDescuento = this.detalleSeleccionado.strClaveDescuento;
          detalle.strComentarioCancelacion = this.detalleSeleccionado.strComentarioCancelacion;
          detalle.intPOSMotivoCancelacion = this.detalleSeleccionado.intPOSMotivoCancelacion;
          detalle.intCancelacionDevolucion = this.detalleSeleccionado.intCancelacionDevolucion;
          detalle.intEstatus = this.detalleSeleccionado.intEstatus;
          detalle.intPOSDescuento = this.detalleSeleccionado.intPOSDescuento;
          detalle.intTipoDescuento = this.detalleSeleccionado.intTipoDescuento
        }
      });
      this.cancelarDet.usuario = "";
      this.cancelarDet.motivo = 0;
      this.cancelarDet.posdet = {};
      this.cancelarDet.tipo = 9;
    },
    async setDomicilio(domicilio) {
      console.log(domicilio.intEmpleado)
      this.POSEnc.strTelefono = domicilio.strTelefono;
      this.POSEnc.strNombre = domicilio.strNombre;
      this.POSEnc.strDireccion = domicilio.strDireccion;
      this.POSEnc.strColonia = domicilio.strColonia;
      this.POSEnc.strEntreCalle = domicilio.strEntreCalle;
      this.POSEnc.strReferencia = domicilio.strReferencia;
      this.POSEnc.strTelefono2 = domicilio.strTelefono2;
      this.POSEnc.strPoblacion = domicilio.strPoblacion;
      this.POSEnc.intEmpleado = domicilio.intEmpleado;
      this.POSEnc.strRepartidor = domicilio.strRepartidor;
      this.POSEnc.dblDomicilioPagaCon =domicilio.dblDomicilioPagaCon;
      this.POSEnc.dblCambio = domicilio.dblDomicilioPagaCon-this.POSEnc.dblTotal;

       await this.ordenFunctions.saleRepartidor(
          this.POSEnc.intPOSEnc,
          this.POSEnc.intEmpleado,
          this.session.sucursal,
          this.session.register,
        );
       
        const config = this.configuraciones.find((c) => c.id == 1);
        if (config.valor) {
          this.printPOSPrepago(this.POSEnc) 
        }
        this.nuevoPOSEnc();
        this.dataController.loadOrdenes().then((ordenes) => {
                    this.ordenes = ordenes;
                  });
    },
    async seleccionaOpcion(opcion) {
      console.log(opcion)
      this.activeOpciones = false;
      try {
        if (opcion == "registro-inventario") {
          this.$router.push("/Inventario");
        } else if (opcion == "cancelar-devolver") {
          this.cancelarDevolverVisible.idm = this.session.terminal;
          this.cancelarDevolverVisible.register = this.intPOSRegister;
          this.cancelarDevolverVisible.visible = true;
        } else if (opcion == "adminsitrador-tickets") {
          this.autoriza.event = "adminsitrador";
          this.autoriza.visible = true;
          // this.$router.push("/AdministradorTicket");
        } else if (opcion == "reimpresion") {
          //this.$router.push("/Reimpresion");
          this.autoriza.event = "reimpresion";
          this.autoriza.visible = true;
        } else if (opcion == "facturar") {
          this.$router.push("/Facturacion");
        } else if (opcion == "corte-baucher") {
          if (this.session.tipo == "comandero")
            throw "No es posible realizar accion. Solo el CAJERO puede hacerlo";
          this.$router.push("/Vouchers");
        } else if (opcion == "salida-caja") {
          if (this.session.tipo == "comandero")
            throw "No es posible realizar accion. Solo el CAJERO puede hacerlo";
          this.$router.push("/SalidaCaja");
        } else if (opcion == "meseros") {
          if (this.session.tipo == "comandero")
            throw "No es posible realizar accion. Solo el CAJERO puede hacerlo";
          this.$router.push("/RegistroMesero");
        } else if (opcion == "arqueo") {
          if (this.session.tipo == "comandero")
            throw "No es posible realizar accion. Solo el CAJERO puede hacerlo";
          this.confirm.title = "Arqueo";
          this.confirm.text = "¿Desea hacer un arqueo?";
          this.confirm.visible = true;
          this.confirm.event = opcion;
        } else if (opcion == "heredar-cuentas") {
          this.confirm.title = "Heredar cuentas";
          this.confirm.text = "¿Esta seguro de heredar cuentas al proximo turno?";
          this.confirm.visible = true;
          this.confirm.event = opcion;
        } else if (opcion == "cierre-turno") {
          try {
            this.loading = true;
            //* Validacion de cuentas pendientes
            if (this.session.tipo == "comandero")
              throw "No es posible cerrar el turno. Solo el CAJERO puede hacerlo";
            const pendientes = await this.registerFunctions.pendientes();
            if (pendientes.length > 0)
              throw "Se tienen ordenes pendientes de terminar, favor de terminarlas o heredarlas al proximo turno de esta caja";
            //*Se agrega confirmacion de voucher obligatoria
            const  cierre = await this.registerFunctions.cierre();

            // console.log(cierre.intPermiteSalida)
            console.log(this.salida_comision)
            console.log(this.confirmacion_voucher)

            if (this.salida_comision.valor && cierre.intPermiteSalida == 0)
              throw "No es posible cerrar el turno, captura las salidas por comision.";

            if (this.confirmacion_voucher.valor && cierre.intConfirmaTarjetas == 0) {
                this.$toast.error("No es posible cerrar el turno. Confirme la captura de Vouchers",new Toast());
                this.$router.push("/Vouchers");
            }

            this.confirm.title = "Cierre de turno";
            this.confirm.text = "¿Esta seguro de cerrar turno?";
            this.confirm.visible = true;
            this.confirm.event = opcion;
          } catch (e) {
            this.$toast.error(ErrorWeb(e), new Toast());
          } finally {
            this.loading = false;
          }
        } else if (opcion == "cerrar-sesion") {
          this.confirm.title = "Cerrar sesion";
          this.confirm.text = "¿Esta seguro de cerrar sesion?";
          this.confirm.visible = true;
          this.confirm.event = opcion;
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
    async aceptarConfirm(event) {
      this.activeOpciones = false;
      if (event == "arqueo") {
        this.arqueo();
      } else if (event == "eliminar-detalle") {
        this.eliminarDetalleValido();
      } else if (event == "cierre-turno") {
        this.cerrarTurno();
      } else if (event == "heredar-cuentas") {
        await this.heredarCuentas();
      } else if (event == "cerrar-sesion") {
        this.cerrarSesion();
      } else if (event == "cerrar-turno-validado") {
        this.cerrarTurno();
      }
      this.confirm.visible = false;
    },
    async authorized(event) {
      this.auth.visible = false;
      if (event.name == "heredar") {
        this.loading = true;
        try {
          let Herencia = new POSHerencia();
          Herencia.intEmpresa = this.session.empresa;
          Herencia.strUsuarioOriginal = this.session.usuario;
          Herencia.strTerminal = this.terminal.strNombre;
          await this.registerFunctions.heredar(Herencia);
          this.ordenes = [];
          this.$toast.success("Las cuentas han sido puestas en herencia", new Toast());
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    async heredarCuentas() {
      //? Se ocupa validacion?
      // this.confirm.visible = false;
      // this.auth.visible = true;
      // this.auth.event = "heredar";
      this.loading = true;
      try {
        let Herencia = new POSHerencia();
        Herencia.intEmpresa = this.session.empresa;
        Herencia.strUsuarioOriginal = this.session.usuario;
        Herencia.intPOSRegisterOriginal = this.session.register;
        Herencia.strTerminal = this.terminal.strNombre;
        await this.registerFunctions.heredar(Herencia);
        this.ordenes = [];
        this.$toast.success("Las cuentas han sido puestas en herencia", new Toast());
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    arqueo() {
      this.$router.push("/Caja/2");
    },
    cerrarTurnoValidacion() {
      this.confirm.title = "Arqueo Final";
      this.confirm.text = "¿Ya realizó el arqueo final?";
      this.confirm.visible = true;
      this.confirm.event = "cerrar-turno-validado";
    },
    cerrarTurno() {
      this.$router.push("/Caja/3");
    },
    cerrarSesion() {
      //* Destruimos session y redirigimos al login
      this.session.logout();
      this.$router.push("/Login");
    },
    async eliminarPOSEnc() {
      this.loading = true;
      try {
        //Se tiene que hacer codigo para llamar a servidor
        this.modalOrdenOpcionVisible = false;
        this.nuevoPOSEnc();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    juntarPOSEnc() {
      this.modalOrdenOpcionVisible = false;
      this.juntar_cuenta.visible = true;
      this.juntar_cuenta.POSEnc = this.POSEnc;
    },
    separarPOSEnc() {
      this.modalOrdenOpcionVisible = false;
      this.separar_cuenta.visible = true;
      this.separar_cuenta.POSEnc = this.POSEnc;
    },
    abrirCortesia() {
      try {
        if (this.POSEnc.intPOSEnc == 0)
          throw "No se puede aplicar cortesia, primero mande a preparar la orden";

        this.modalOrdenOpcionVisible = false;
        this.cortesia.isVisible = true;
        this.cortesia.POSEnc = this.POSEnc;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
    descuentoPOSEnc() {
      this.modalOrdenOpcionVisible = false;
      if (this.POSEnc.dblPorcentajeDescuento == 0) {
        this.descuentoEnc.posenc = this.POSEnc;
        this.descuentoEnc.isVisible = true;
      } else {
        this.$toast.error(
          "No se puede agregar otro descuento a la orden, ya cuenta con uno de " +
            this.POSEnc.dblPorcentajeDescuento +
            "%",
          new Toast()
        );
      }
    },
    abrirSeleccion() {
      this.activeOpciones = true;
    },
    convertDateJSON(POSEnc) {
      if (POSEnc.datFecha.toString().includes("T")) {
        return POSEnc.datFecha;
      } else {
        let x = POSEnc.datFecha.split(" ");
        let y = x[0].split("/");
        let response = y[2] + "-" + y[1] + "-" + y[0] + "T" + x[1] + ".511Z";
        return response;
      }
    },
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
      );
    },
    printEncabezado() {
      this.loading = true;
      try {
        if (this.POSEnc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de seleccionar la orden.";

        this.autoriza.event = "imprimir";
        this.autoriza.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async printPOSPrepago(posenc) {
      this.loading = true;

      try {
        if (posenc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de guardarla";

        //Impresion de Ticket Preparacion
        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        if (!posenc.posDet) {
          posenc.posDet = await this.ordenFunctions.detalles(posenc.intPOSEnc);
        }
        this.printer.event = "prepago";
        this.printer.visible = true;
        posenc.datFechaImpresion = this.convertDateJSON(posenc);
        if (posenc.intHotReservacion) {
          if (posenc.intHotReservacion > 0) {
            let reservacion = await this.ordenFunctions.get_reservacion(
              posenc.intHotReservacion
            );
            posenc.strHabitacion = reservacion.strHabitacion;
            posenc.strApellidoReferencia = reservacion.strApellidoReferencia;
          }
        }
        this.printer.printable = posenc;

        ////TicketFunctions.ticketOrden(this.dataEmpresa, this.terminal.strNombre, posenc);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async printPOS(posenc) {
      this.loading = true;

      try {
        if (posenc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de guardarla";

        //Impresion de Ticket Preparacion
        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        if (!posenc.posDet) {
          posenc.posDet = await this.ordenFunctions.detalles(posenc.intPOSEnc);
        }
        console.log(posenc)
        this.printer.event = "preparacion";
        this.printer.visible = true;
        posenc.datFechaImpresion = this.convertDateJSON(posenc);
        if (posenc.intHotReservacion) {
          if (posenc.intHotReservacion > 0) {
            let reservacion = await this.ordenFunctions.get_reservacion(
              posenc.intHotReservacion
            );
            posenc.strHabitacion = reservacion.strHabitacion;
            posenc.strApellidoReferencia = reservacion.strApellidoReferencia;
          }
        }
        this.printer.printable = posenc;

        ////TicketFunctions.ticketOrden(this.dataEmpresa, this.terminal.strNombre, posenc);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async printCocina(posenc) {
      this.loading = true;
      try {
        if (posenc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de guardarla";
        //Impresion de Ticket Preparacion
        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
          let details = [];
          let intPOSimpresora = this.impresoras.find(element => element.name == 'COCINA').intPOSImpresora;
          const detalle = await this.ordenFunctions.detalles(posenc.intPOSEnc);
          detalle.forEach((x) => {
            if(x.intPOSImpresora == intPOSimpresora){
              details.push(x); }
          });
          posenc.posDet =details;

          if(details.length>0){
            this.printer.event = "cocina";
            this.printer.visible = true;
            posenc.datFechaImpresion = this.convertDateJSON(posenc);
            this.printer.printable = posenc;
          }

      

      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async reprintPOSPago(posenc) {
      try {
        if (posenc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de guardarla";

        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        if (!posenc.posEncPago)
          posenc.posEncPago = await this.ordenFunctions.pagos(posenc.intPOSEnc);

        if (!posenc.posDet)
          posenc.posDet = await this.ordenFunctions.detalles(posenc.intPOSEnc);

        if (posenc.intHotReservacion) {
          if (posenc.intHotReservacion > 0) {
            let reservacion = await this.ordenFunctions.get_reservacion(
              posenc.intHotReservacion
            );
            posenc.strHabitacion = reservacion.strHabitacion;
            posenc.strApellidoReferencia = reservacion.strApellidoReferencia;
          }
        }
        posenc.bolReimpresion = true;
        this.printer.event = "pago";
        this.printer.visible = true;
        posenc.datFechaImpresion = this.toIsoString(new Date());
        this.printer.printable = posenc;

        //TicketFunctions.ticketPago(this.dataEmpresa, this.terminal.strNombre, posenc);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
    async printPOSPago(posenc) {
      try {
        if (posenc.intPOSEnc == 0)
          throw "No se puede imprimir la orden, favor de guardarla";

        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        if (!posenc.posEncPago)
          posenc.posEncPago = await this.ordenFunctions.pagos(posenc.intPOSEnc);

        if (!posenc.posDet)
          posenc.posDet = await this.ordenFunctions.detalles(posenc.intPOSEnc);

        if (posenc.intHotReservacion) {
          if (posenc.intHotReservacion > 0) {
            let reservacion = await this.ordenFunctions.get_reservacion(
              posenc.intHotReservacion
            );
            posenc.strHabitacion = reservacion.strHabitacion;
            posenc.strApellidoReferencia = reservacion.strApellidoReferencia;
          }
        }

        this.printer.event = "pago";
        this.printer.visible = true;
        posenc.datFechaImpresion = this.toIsoString(new Date());
        this.printer.printable = posenc;

        //TicketFunctions.ticketPago(this.dataEmpresa, this.terminal.strNombre, posenc);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
    // arqueo_automatico() {
    //   if (this.corte_max.activo == true) {
    //     axios
    //       .get(
    //         CSTRHOSTNAME +
    //           CSTRAPPNAME +
    //           "/TPV/POSLite/POS.aspx?action=POS_ArqueoMax&register=" +
    //           this.intPOSRegister
    //       )
    //       .then((res) => {
    //         if (res.data >= this.corte_max.valor) {
    //           this.max_arqueo = true;
    //         } else {
    //           this.max_arqueo = false;
    //         }
    //       });
    //   }

    async arqueo_automatico() {
      if (this.corte_max.activo) {
        const arqueo_max = await  this.registerFunctions.arqueoMax();
        console.log("arqueo",arqueo_max.dblEfectivoCaja)
        // console.log("entroarqueo",arqueo_max.dblEfectivoCaja)
        if(arqueo_max.dblEfectivoCaja > this.corte_max.valor)
        {
          console.log("entroarqueo",arqueo_max.dblEfectivoCaja)
          this.$toast.error("Es necesario realizar un arqueo. Se llego al limite de: $" + this.corte_max.valor , new Toast());
          this.arqueo();
        }
      }
    },
    recargar_ordenes() {
      this.loading = true;
      try {
        let tipo = Cookies.get("POS_TIPO");
        if (tipo.toUpperCase() == "CAJERO") {
          this.dataController.loadOrdenes().then((ordenes) => {
            this.ordenes = ordenes.filter((x) => {
              return x != null;
            });
          });
        }

        if (tipo == "COMANDERO") {
          this.dataController
            .loadOrdenesUsuario(this.dataMesero.intPOSMesero)
            .then((ordenes) => {
              this.ordenes = ordenes.filter((x) => {
                return x != null;
              });
            });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async agregarPOSDet() {
      try {
        if (this.POSEnc.intPOSEnc == 0)
          throw "No se puede realizar esta accion, se debe mandar a preparar la orden";

        this.disablePOSEnc = true;
        //** El proceso pago 3 significa agregar nuevo detalle al posenc
        this.procesoPago = 3;
        this.modalOrdenOpcionVisible = false;
        if (this.POSEnc.intPOSTipoServicio == 1) {
          this.tipo.tipo = "mesa";
          //? Checar esto
          this.tipo.mesapago.mesa = parseInt(this.POSEnc.intPOSMesa);
        } else if (this.POSEnc.intPOSTipoServicio == 2) this.tipo.tipo = "domicilio";
        else if (this.POSEnc.intPOSTipoServicio == 3) {
          this.tipo.claveLlevar.clave = "llevar";
          this.tipo.claveLlevar.tipo = this.POSEnc.intPOSTipoVenta;
        }
        this.disableTipoPago = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
  },
  async created() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.intPOSRegister = parseInt(this.session.register.toString());
        // se muestran las ordenes por terminal 
        this.ordenesXTerminal = this.configuraciones.find((c) => c.id == 19);
        // *? Se tiene que quitar, solo esta en pruebas
        // this.session.empresa = 3;
        //*Insertamos variables  de sesion a POSENC
        this.POSEnc.intPOSRegister = this.session.intPOSRegister;
        this.POSEnc.strUsuarioPOS = this.session.usuario;

        this.terminalespago.push({ intPOSTerminalPago: 0, strNombre: "NINGUNA" });
        this.tipoventas.push({
              datFechaAlta: null,
              datFechaMod: null,
              intEmpresa: this.session.empresa,
              intPOSTipoVenta: 0,
              intSucursal: 0,
              intTipoPrecio: 0,
              strMaquinaAlta: null,
              strMaquinaMod: null,
              strNombre: "NINGUNA",
              strNombreCorto: "NINGUNA",
              strUsuarioAlta: null,
              strUsuarioMod: null,
            });
        // ** SI TENEMOS ARTICULOS EN INDEXED DB NO CARGAMOS NADA
        
        let articulos = await db.articulos.count();
        if (articulos == 0) {
          console.log("Cargando informacion desde SQL...")
          this.meseros = await this.dataController.loadMeserosRegister();
          this.meseros.forEach(async (x) => { await db.meseros.add(x)});
          this.data = await this.dataController.loadDataPOS();
          let tipoventas = await this.dataController.loadTipoVentas();
          tipoventas.forEach((x) => this.tipoventas.push(x));
          tipoventas.forEach(async (x) => { await db.tiposVenta.add(x)})

          let terminales_pago = await this.dataController.loadTerminalesPago();
          terminales_pago.forEach((x) => {
            this.terminalespago.push(x);
          });
          terminales_pago.forEach(async (x) => { await db.terminalPago.add(x)})
          //*0 - Articulos
          this.articulos = this.data[0];
          this.articulos.forEach(async (x) => { await db.articulos.add(x); });
          this.grupos = this.data[1];
          this.grupos.forEach(async (x) => { await db.grupos.add(x); });
          //*2 - Tipo Pagos
          this.tiposPago = this.data[2];
          this.tiposPago.forEach(async (x) => { await db.tiposPago.add(x); });
          //*3 - Ordenes activas
          //*Si es cajero puede ver todas las ordenes, si es comandero solo las suyas
          //! SE SIGUE CARGANDO
          this.dataMesero = await this.userFunctions.mesero(
            this.session.empresa,
            this.session.usuario
          );
          if (this.session.tipo.toUpperCase() == "CAJERO") {

            if(this.ordenesXTerminal.valor)
            {
              this.ordenes = (await this.dataController.loadDataTicketTerminal()).data;
              this.ordenes = this.ordenes.filter(x => x != null);
            }
            else {
              this.ordenes = (await this.dataController.loadDataTicket()).data;
              this.ordenes = this.ordenes.filter(x => x != null);

            }
          }

          if (this.session.tipo.toUpperCase() == "COMANDERO") {
            this.ordenes = await this.dataController.loadOrdenesUsuario(this.dataMesero.intPOSMesero);
            this.meseros = this.meseros.filter(x => x.intPOSMesero == this.dataMesero.intPOSMesero);
          }

          this.ordenes.sort((a, b) => (a.intPOSEnc > b.intPOSEnc ? -1 : 1));


          //*4 - Configuraciones
          this.articulo_configuraciones = this.data[4];
          this.articulo_configuraciones.forEach(async (x) => { await db.articulo_configuraciones.add(x); });
          //*Quitamos ordenes de aqui en adelante, ordenes pagadas
          this.ordenes = this.ordenes.filter(orden => orden.intEstatus < 4 );
          //*5 - Motivos Devolucion
          this.repartidores = this.data[5];
          this.repartidores.forEach(async (x) => {await db.repartidores.add(x); });
          //*6 - Motivos Devolucion
          this.motivosDevolucion = this.data[6];
          this.motivosDevolucion.forEach(async (x) => {
            await db.motivosDevolucion.add(x);
          });
          //*8 - Motivos Descuento
          this.motivosDescuento = this.data[8];
          this.motivosDescuento.forEach(async (x) => {
            await db.motivosDescuento.add(x);
          });
          //*9 - Colonias
          this.colonias = this.data[9];
          this.colonias.forEach(async (x) => {
            await db.colonias.add(x);
          });
          //*13 - Impresoras
          this.impresoras = await this.dataController.loadImpresoras();
          this.impresoras.forEach(async (x) => {
            await db.impresoras.add(x);
          });
          //*14 - Mesas
          //! SE QUEDA IGUAL POR MIENTRAS
          this.mesas = this.data[14];
          this.mesas.forEach(async (x) => {await db.mesas.add(x); });
          //*Registro
          this.POSRegister = await this.registerFunctions.retrieve();
          //*Informacion de empresa
          this.dataEmpresa = (await this.dataController.loadDataEmpresa()).data;
          //*15 - clientes
          this.clientes = this.data[15];
          this.clientes.forEach(async (x) => {
            await db.clientes.add(x);
          });
         
          // let envio=await this.dataController.loadTootleEnvio();
          // envio == null ?  this.tootleEnvio = 0 : this.tootleEnvio = envio


  // console.log(this.tootleEnvio)
        }else {
          console.log("Cargamos informacion desde indexed DB...")
          //* CARGAMOS TODO DE INDEXED DB
          this.meseros = await db.meseros.toArray();
          let tipoVentasDb = await db.tiposVenta.toArray();
          tipoVentasDb.forEach(x => {
            this.tipoventas.push(x);
          });
          let terminales_pago = await db.terminalPago.toArray();
          terminales_pago.forEach((x) => this.terminalespago.push(x));
          // this.terminalespago = await db.terminalPago.toArray();
          this.articulos = await db.articulos.toArray();
          this.articulos_db = this.articulos;
          this.grupos = await db.grupos.toArray();
          this.mesas = await db.mesas.toArray();
          this.repartidores = await db.repartidores.toArray();
          this.tiposPago = await db.tiposPago.toArray();
          this.articulo_configuraciones = await db.articulo_configuraciones.toArray();
          this.motivosDevolucion = await db.motivosDevolucion.toArray();
          this.motivosDescuento = await db.motivosDescuento.toArray();
          this.colonias = await db.colonias.toArray();
          this.clientes = await db.clientes.toArray();
          this.impresoras = await db.impresoras.toArray();
          console.log('Termina la carga de indexed DB!')
          //! SE SIGUE CARGANDO
          this.dataMesero = await this.userFunctions.mesero(
            this.session.empresa,
            this.session.usuario
          );

          if (this.session.tipo.toUpperCase() == "CAJERO") {
            if(this.ordenesXTerminal.valor)
              this.ordenes = (await this.dataController.loadDataTicketTerminal())
                            .data.filter(x => x != null);
            else 
              this.ordenes = (await this.dataController.loadDataTicket())
                            .data.filter(x => x != null);
          }else if (this.session.tipo.toUpperCase() == "COMANDERO") {
            this.ordenes = await this.dataController.loadOrdenesUsuario(
              this.dataMesero.intPOSMesero
            );
            this.meseros = this.meseros.filter((x) => {
              return x.intPOSMesero == this.dataMesero.intPOSMesero;
            });
          }

          this.ordenes.sort((a, b) => (a.intPOSEnc > b.intPOSEnc ? -1 : 1));
        
          //*Quitamos ordenes de aqui en adelante, ordenes pagadas
          this.ordenes = this.ordenes.filter(orden => orden.intEstatus < 4);
          this.POSRegister = await this.registerFunctions.retrieve();
          this.dataEmpresa = (await this.dataController.loadDataEmpresa()).data;
          // this.tootleEnvio = await this.dataController.loadTootleEnvio();
          // let envio=await this.dataController.loadTootleEnvio();
          // envio == null ?  this.tootleEnvio = 0 : this.tootleEnvio = envio

          // console.log(this.tootleEnvio)
        }
        //*Configuraciones

        
        this.corte_max = this.configuraciones.find((c) => c.id == 2);
        this.preparacion = this.configuraciones.find((c) => c.id == 3);
        this.comandero_conf = this.configuraciones.find((c) => c.id == 4);
        this.articulo_especial = this.configuraciones.find((c) => c.id == 5);
        this.bebida_especial = this.configuraciones.find((c) => c.id == 13);
        this.codigo_barra = this.configuraciones.find((c) => c.id == 14);
        this.confirmacion_voucher = this.configuraciones.find((c) => c.id == 6);
        this.salida_comision = this.configuraciones.find((c) => c.id == 7);
        this.envio_ticket = this.configuraciones.find((c) => c.id == 15);
        this.version_domicilio = this.configuraciones.find((c) => c.id == 16);
        this.envio_default = this.configuraciones.find((c) => c.id == 17);
        this.ordenesXTerminal = this.configuraciones.find((c) => c.id == 19);
          
        //* Terminal
        this.terminal = await this.userFunctions.terminal(this.session.terminal);
        this.POSInfo = await this.dataController.loadDataPOSInfo();
        
        //* Configuracion 3, indica si se salta el proceso de preparacion y va directamente a venta
        this.procesoPago = this.preparacion.valor ? 2 : 1;
        if (this.session.tipo == "comandero") {
          this.acciones = this.acciones.filter((x) => x.comandero);
        }

        const sincronizacion = this.configuraciones.find((c) => c.id == 9);
        if (sincronizacion) {
          if (sincronizacion.activo) {
            if (this.session.tipo == "comandero") {
              setInterval(() => {
                this.dataController
                  .loadOrdenesUsuario(this.dataMesero.intPOSMesero)
                  .then((ordenes) => {
                    this.ordenes = ordenes;
                  });
              }, 60000 * parseInt(sincronizacion.valor));
            }
            if (this.session.tipo == "cajero") {
              if(this.ordenesXTerminal.valor){
                  setInterval(() => {
                  this.dataController.loadOrdenesXTerminal().then((ordenes) => {
                    this.ordenes = ordenes;
                  });

                }, 60000 * parseInt(sincronizacion.valor));
              }else{
                setInterval(() => {
                this.dataController.loadOrdenes().then((ordenes) => {
                    this.ordenes = ordenes;
                  });

                }, 60000 * parseInt(sincronizacion.valor));
              }
            }
          }
        }


      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
      //*? PENDIENTE Ver si se puede volver a aplicar esta metrica, si no, cambiarla
      // this.arqueo_automatico();
    }
  },
 
};
</script>

<style>
.title {
  color: white;
}
/* .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-enter {
        transform: translateY(10px);
        opacity: 0;
    } */
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

//* Vistas
import Reimpresion from '../views/Reimpresion.vue';
import Login from '../views/Login.vue';
import Facturacion from '../views/Facturacion.vue';
import RegistroCaja from '../views/RegistroCaja.vue';
import RegistroMesero from '../views/RegistroMesero.vue';
import AdministradorTicket from '../views/AdministradorTicket.vue';
import SalidaCaja from '../views/SalidaCaja.vue';
import Inventario from '../views/Inventario.vue';
import Admin from '../views/Admin.vue';
import Articulo from '../views/Admin/Articulo.vue';
import Familia from '../views/Admin/Familia.vue';
import MotivoCancelacion from '../views/Admin/MotivoCancelacion.vue';
import MotivoDescuento from '../views/Admin/MotivoDescuento.vue';
import TipoPago from '../views/Admin/TipoPago.vue';
import Usuario from '../views/Admin/Usuario.vue';
import ConfiguracionArticulo from '../views/Admin/ConfiguracionArticulo.vue';
import ConfiguracionArticuloChild from '../views/Admin/ConfiguracionArticuloChild.vue';
import DemoKeyboard from '../views/DemoKeyboard.vue';
import Cocina from '../views/Cocina.vue';



import LoginAdmin from '../views/Admin/Login.vue';

import Voucher from '../views/Voucher.vue';
import Main from '../views/Main.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/registromesero',
    name: 'RegistroMesero',
    component: RegistroMesero
  },
  {
    path: '/caja/:opcion',
    name: 'Caja',
    component: RegistroCaja,
    props: true
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/facturacion',
    name: 'Facturacion',
    component: Facturacion
  },
    {
      path: '/reimpresion',
      name: 'Reimpresion',
      component: Reimpresion
    },
  {
    path: '/AdministradorTicket',
    name: 'AdministradorTicket',
    component: AdministradorTicket
  },
  {
    path: '/SalidaCaja',
    name: 'SalidaCaja',
    component: SalidaCaja
  },
  {
    path: '/Vouchers',
    name: 'Vouchers',
    component: Voucher
  },
  {
    path: '/Inventario',
    name: 'Inventario',
    component: Inventario
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/Admin/Articulo',
    name: 'Articulo',
    component: Articulo
  },
  {
    path: '/Admin/Familia',
    name: 'Familia',
    component: Familia
  },
  {
    path: '/Admin/MotivoCancelacion',
    name: 'MotivoCancelacion',
    component: MotivoCancelacion
  },
  {
    path: '/Admin/MotivoDescuento',
    name: 'MotivoDescuento',
    component: MotivoDescuento
  },
  {
    path: '/Admin/TipoPago',
    name: 'TipoPago',
    component: TipoPago
  },
  {
    path: '/Admin/Usuario',
    name: 'Usuario',
    component: Usuario
  },
  {
    path: '/Admin/ConfiguracionArticulo',
    name: 'ConfiguracionArticulo',
    component: ConfiguracionArticulo
  },
  {
    path: '/Admin/ConfiguracionArticulo/:id',
    name: 'ConfiguracionArticuloRetrieve',
    component: ConfiguracionArticulo,
    props: true
  },
  {
    path: '/Admin/ConfiguracionArticulo/Child/:id',
    name: 'ConfiguracionArticuloChild',
    component: ConfiguracionArticuloChild,
    props: true
  },
  {
    path: '/Admin/Login',
    name: 'LoginAdmin',
    component: LoginAdmin
  },
  {
    path: '/Cocina/intSucursal=:id',
    name: 'Cocina',
    component: Cocina
  },
  
  {
    path: '/Demo',
    name: 'Demo',
    component: DemoKeyboard
  },
  { path: '/', redirect: '/Login' }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

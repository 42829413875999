import axios from 'axios';
import { Session } from './DataController';

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

class POSInventarioFunctions{
    
    async articulos(){
        const session = new Session();

        const response = await axios.get("POS_InventarioArticulos", {
            headers: session
        })
        return response.data;
    }
    async movimientos(){
        const session = new Session();

        const response = await axios.get("POS_Inventario", {
            headers: session
        })
        return response.data;
    }
    async saldos(){
        const session = new Session();

        const response = await axios.get("POS_InventarioExistencias", {
            headers: session
        })
        return response.data;
    }
    async entrada(data){
        const session = new Session();

        const response = await axios.post("POS_Inventario/1", data, {
            headers: session
        })
        return response.data;
    }
    async salida(data){
        const session = new Session();

        const response = await axios.post("POS_Inventario/2", data, {
            headers: session
        })
        return response.data;
    }
}


class POSTerminalFunctions{
    async retrieve(){
        const session = new Session();

        const response = await axios.get("POS_DataTerminal", {
            headers: session
        })
        return response.data;
    }
}
class POSValidaciones{
    async reporteValidacion(){
        const session = new Session();

        const response = await axios.get("POS_ReporteValidacion", {
            headers: session
        })
        return response.data;
    }
}

class POSMeseroFunctions{
    async nip(nip){
        const session = new Session();

        const response = await axios.post("POS_ValidacionNIP", {
            nip: nip
        }, {
            headers: session
        })
        return response.data;
    }
}

class POSRegisterFunctions{
    async register_actual(empresa, terminal){
        const response = await axios.get("POS_RegisterActual/" + empresa + "/" + terminal)
        return response.data;
    }
    async register_actualUsuario(empresa, terminal,usuario){
        const response = await axios.get("POS_RegisterActual/" + empresa + "/" + terminal+ "/" + usuario)
        return response.data;
    }
    async retrieve(){
        const session = new Session();

        const response = await axios.get("POS_DataRegister", {
            headers: session
        })
        return response.data;
    }
    async cierre(){
        const session = new Session();

        const response = await axios.get("POS_DataCierre", {
            headers: session
        })
        return response.data[0];
    }
    async pendientes(){
        const session = new Session();

        const response = await axios.get("POS_OrdenesPendientes", {
            headers: session
        })
        return response.data;
    }
    async heredar(post_data){
        const session = new Session();

        const response = await axios.post("POS_HeredaCuentas", post_data, {
            headers: session
        })
        return response.data;
    }
    async recibir(post_data){
        const session = new Session();

        const response = await axios.post("POS_RecibeCuentas", post_data, {
            headers: session
        })
        return response.data;
    }
    async arqueoMax(){
        const session = new Session();

        const response = await axios.get("POS_ArqueoMax", {
            headers: session
        })
        return response.data[0];
    }
}

class POSEncPagoFunctions {
    async getReservas(empresa){
        const session = new Session();

        const response = await axios.get("POS_DataReservas/" + empresa, {
            headers: session
        })
        return response.data;
    }
}

class POSDetFunctions{

    async descuento(posdet){
        const session = new Session();

        const response = await axios.post("POS_DetalleDescuento", posdet, {
            headers: session
        })
        return response.data;
    }
    async cancelacion( posdet){
        const session = new Session();

        const response = await axios.post("POS_DetalleCancelacion", posdet, {
            headers: session
        })
        return response.data;
    }
    async cortesia(intPOSEnc, strUsuario,intPOSMotivoDescuento,comentario){
        const session = new Session();

        const response = await axios.post("POS_Cortesia/" + intPOSEnc + "/" + strUsuario + "/" + intPOSMotivoDescuento 
        + "/" + comentario, null, {
            headers: session
        })
        return response.data;
    }
}

class POSEncFunctions{
    async saveProsepago(intPOSEncPago, data){
        const response = await axios.put("Prosepago/Save/" + intPOSEncPago, data)
        return response.data;
    }

    async ventaProsepago(pago, orden){

        const response = await axios.post("Prosepago/NuevaVenta", {
            tipoPlan:"00",
            terminal: "",
            // importe: pago.dblImporte.toString(),
            importe: "1.00",
            nombre: pago.strNombreTarjeta,
            concepto:"Cobro Ticket #" +  orden + " de " + pago.strMaquinaAlta,
            referencia:"",
            correo: pago.strCorreoElectronico
          })
        return response.data;
    }
    async estatusProsepago(idTransaccion){

        const response = await axios.get("Prosepago/Estatus/" + idTransaccion)
        return response.data;
    }
    async descuento(POSEnc) {
        const session = new Session();
        const response = await axios.post("POS_EncDescuento", 
        POSEnc, {
            headers: session
        })
        return response.data;
    }
    async buscarClienteRFC(rfc) {
        const session = new Session();
        const response = await axios.get("POS_DataClientesRFC/" + rfc, {
            headers: session
        })
        return response.data;
    }
    async retrieve(orden){
        const session = new Session();

        const response = await axios.get("POS_OrdenRetrieve/" +  orden , {
            headers: session
        })
        return response.data;
    }
    async agregar_productos(POSEnc){
        const session = new Session();

        const response = await axios.post("POS_AgregarProductos", 
        POSEnc, {
            headers: session
        })
        return response.data;
    }
   async preparar(POSEnc){
         const session = new Session();

        const response = await axios.post("POS_Save", 
        POSEnc, {
            headers: session
        })
        return response.data;
    }
    async pagar(POSEnc,descuentoalmacen){
        const session = new Session();

        const response = await axios.post("POS_Pagar/"+descuentoalmacen, 
        POSEnc, {
            headers: session
        })
        return response.data;
    }

    async eliminarPago(intPOSEncPago){
        const session = new Session();

        const response = await axios.delete("POS_CancelarPago/" + intPOSEncPago, {
            headers: session
        })
        return response.data;
    }
    async saleRepartidor(intPOSEnc,intEmpleado,sucursal,register) {
        const session = new Session();

        const response = await axios.post("POS_SaleRepa/" + intPOSEnc + "/"+ intEmpleado + "/"+ sucursal + "/"+ register,
        {
           headers: session
        })
        return response.data;
    }
    async dataOrdenes(intSucursal) {
        const session = new Session();

        const response = await axios.get("POS_DataOrdenes/" + intSucursal, {
            headers: session
        })
        return response.data;
    }
   
    async pagos(intPOSEnc){
        const session = new Session();

        const response = await axios.get("POS_GetPagos/" + intPOSEnc, {
            headers: session
        })
        return response.data;
    }
    async pago(intPOSEnc){
        const session = new Session();

        const response = await axios.get("POS_GetPago/" + intPOSEnc, {
            headers: session
        })
        return response.data;
    }
    
    async detalles(intPOSEnc){
        const session = new Session();

        const response = await axios.get("POS_GetDetalles/" + intPOSEnc, {
            headers: session
        })
        return response.data;
    }
    async saveHerencia(POSEnc){
        const session = new Session();

        const response = await axios.post("POS_SaveHerencia", 
        POSEnc, {
            headers: session
        })
        return response.data;
    }
    async saveCliente(obj){
        const session = new Session();

        const response = await axios.post("POS_SaveCliente", 
        obj, {
            headers: session
        })
        return response.data;
    }
    async editarCliente(obj){
        const session = new Session();

        const response = await axios.put("POS_SaveCliente", 
        obj, {
            headers: session
        })
        return response.data;
    }
    async finalizarOrden(intPOSEnc,intSucursal){
        const session = new Session();
        const response = await axios.post("POS_POSEstatusFinalizar/" + intPOSEnc + "/" + intSucursal,
        {
            headers: session
        })
        return response.data;
    }
    async clientes(strTelefono){
        console.log(strTelefono)
        const session = new Session();

        const response = await axios.get("POS_DataClientes/" + strTelefono, {
            headers: session
        })
        return response.data;
    }
    async dataclientes(){
        const session = new Session();

        const response = await axios.get("POS_DataClient" , {
            headers: session
        })
        return response.data;
        
    }
    async administradorTicket(empresa, version, data){
        const session = new Session();
        console.log(data.usuario)

        const response = await axios.post("POS_AdministradorTickets/" + empresa + "/" + version, 
        data, {
            headers: session
        })
        return response.data;
    }
    async getArqueos(intPOSRegister) {
        const session = new Session();

        const response = await axios.get("POS_GetArqueos/"+intPOSRegister, {
            headers: session
        })
        return response.data;
    }

    async retrieveCliente(intCliente){
        const session = new Session();

        const response = await axios.get("POS_DataCliente/" +intCliente, {
            headers: session
        });
        return response.data;
    }

    async getOrdenReimpresion(fecha, version, orden) {
        const session = new Session();

        const response = await axios.get("POS_OrdenReimpresion/" + fecha + "/" + version + "/" + orden,
            {
                headers: session
            })
        return response.data;
    }


    async getPOSFacturacion(data) {
        const session = new Session();
        const response = await axios.post("POS_GetPOSFactura",
            data, {
                headers: session
            })
        return response.data;
    }

    async facturar(data) {
        const session = new Session();
        //! ESTO SE DEBE DE EVITAR, AL CAMBIAR LA SUCURSAL PREDETERMINADA CAMBIARIA TODO ESTO
        session.sucursal = 1;
        data.empresa = session.empresa;
        data.sucursal = 1;
        const response = await axios.post("POS_Facturar",
            data, {
                headers: session
            })
        return response.data;
    }
    
    async vouchers(){
        const session = new Session();

        const response = await axios.get("POS_Vouchers", {
            headers: session
        })
        return response.data;
    }
    async actualizaVouchers() {
        const session = new Session();

        const response = await axios.put("POS_Vouchers", this, {
            headers: session
        })
        return response.data;
    }
    async UpdateCierrePagos(intPOSEnc, intPOSEncPago, intTipoPago,dblImporte,  strReferencia) {
        const session = new Session();
        const response = await axios.post("POS_UpdateCierrePagos/" + intPOSEnc + "/" + intPOSEncPago + "/" + intTipoPago + "/" +
        dblImporte + "/" + strReferencia , null, 
        {
            headers: session
        })
        return response.data;
    }
    async confirmaVouchers(){
        const session = new Session();

        const response = await axios.post("POS_ConfirmaVouchers", null, {
            headers: session
        })
        return response.data;
    }
    async cancelar(POSEnc){
        const session = new Session();
        console.log("entro",POSEnc )
        const response = await axios.post("POS_CancelarEnc",POSEnc, {
            headers: session
        })
        return response.data;
    }
    async cancelarEnc(intPOSEnc, strTerminal, strUsuario,intPOSMotivoCancelacion,strComentario,intCancelacionDevolucion){
        let session = new Session();
        // session.sucursal = 1; //! CODIGO DURO SUCURSAL
        const response = await axios.post("POS_CancelarDevolver/" + intPOSEnc + "/" + strTerminal + "/" + strUsuario + "/" +
        intPOSMotivoCancelacion  + "/" + strComentario + "/" + intCancelacionDevolucion, 
        null, {
            headers: session
        })
        return response.data;
    }
    async retrieve_id(id){
        const session = new Session();

        const response = await axios.get("POS_OrdenRetrieveID/" + id, {
            headers: session
        })
        return response.data;
    }
    async retrieve_idDet(id,intSucursal){
        const session = new Session();

        const response = await axios.get("POS_OrdenRetrieveIDDet/" + id + "/"+ intSucursal, {
            headers: session
        })
        return response.data;
    }
    async get_reservacion(id){
        const session = new Session();

        const response = await axios.get("POS_GetReservacion/" + id, {
            headers: session
        })
        return response.data;
    }
}

class POSSalidaCaja{
    
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intPOSSalidaCaja = 0;
        this.intPOSTipoSalida = null;
        this.intPOSSalida = 0;
        this.dblImporte = 0.00;
        this.strComentario = "";
        this.intPOSRegister = null;
        this.strUsuarioAlta = null;
        this.strUsuarioMod = null;
        this.datFechaAlta = null;
        this.datFechaMod = null;
        this.strMaquinaAlta = null;
        this.strMaquinaMod = null;
        this.intEmpleado = null;
        this.intComisionRepa =  0;
        this.dblPrecio=0.00;
        this.strPOSTipoSalida="";
        this.strPOSSalida="";
        this.strUsuarioSalida="";
        this.strEmpleado="";
        this.intEnvios = 0;
        this.dataDetalleComision=[]
        
    }
    
    async SalidasXTipo(intPOSTipoSalida) {
        const session = new Session();

        const response = await axios.get("POS_SalidaCajaTipo/"+ intPOSTipoSalida, {
            headers: session
        })
        return response.data;
    }

    async Cajeros() {
        const session = new Session();

        const response = await axios.get("POS_SalidaCajero", {
            headers: session
        })
        return response.data;
    }

    async Repartidores() {
        const session = new Session();

        const response = await axios.get("POS_SalidaRepartidor", {
            headers: session
        })
        return response.data;
    }

    async Comision(intEmpleado,intComisionRepa) {
        const session = new Session();

        const response = await axios.get("POS_ComisionEmpleado/" + intEmpleado + "/" + intComisionRepa, {
            headers: session
        })
        return response.data;
    }

    async Registrar(intComisionRepa) {
        const session = new Session();

        const response = await axios.post("POS_ComisionRegistrar/" +  intComisionRepa, this, {
            headers: session
        })
        return response.data[0];
    }

    async Actualizar() {
        const session = new Session();

        const response = await axios.put("POS_ComisionRegistrar", this, {
            headers: session
        })
        return response.data;
    }

    async Data() {
        const session = new Session();

        const response = await axios.get("POS_ComisionRegistrar", {
            headers: session
        })
        return response.data;
    }
    

    async Eliminar(id) {
        const session = new Session();

        const response = await axios.delete("POS_ComisionRegistrar/" + id, {
            headers: session
        })
        return response.data;
    }

    async RetrieveCaja(intPOSSalidaCaja) {
        const session = new Session();

        const response = await axios.get("POS_DetalleComision/"+intPOSSalidaCaja , {
            headers: session
        })
        return response.data;
    }

    async RetrieveCaja_id(intPOSSalidaCaja) {
        const session = new Session();

        const response = await axios.get("POS_ComisionRegistrarRetrieve/"+intPOSSalidaCaja , {
            headers: session
        })
        return response.data;
    }


}

class POSLogImpresion {
    constructor(session) {
        this.intEmpresa = session.empresa;
        this.intSucursal = session.sucursal;
        this.intPOSLogImpresion = 0;
        this.strVersion = null;
        this.intPOSRegister = session.register;
        this.strTipoTicket = null;
        this.intPOSEnc = null;
        this.strDescripcion = null;
        this.intIncluyePreparacion = null;
        this.intTipoMovimientoTurno = null;
        this.intCliente = null;
        this.datFechaInicial = null;
        this.datFechaFinal = null;
        this.intPOSTipoServicio = 0;
        this.intMesero = 0;
        this.intRepartidor = 0;
        this.intIncluyeCorte = 0;
        this.intPOSSalidaCaja = 0;
        this.intPOSRegister2 = 0;
        this.intImpreso = 0;
        this.strError = null;
        this.strTerminal = session.terminal;
        this.strUsuarioAlta = session.usuario;
        this.strMaquinaAlta = null;
        this.datFechaAlta = null;
        this.strUsuarioMod = null;
        this.strMaquinaMod = null;
        this.datFechaMod = null;

    }

    async Save() {
        const session = new Session();

        const response = await axios.post("POS_SaveLogImpresion", this, {
            headers: session
        })
        return response.data;
    }

     async Complete(id) {
        const session = new Session();

        const response = await axios.post("POS_CompleteLog/" + id, {
            headers: session
        })
        return response.data;
    }

    async Data() {
        const session = new Session();

        const response = await axios.get("POS_LogImpresiones", {
            headers: session
        })
        return response.data;
    }

}

class POSLog {
    constructor(session) {
        this.intEmpresa = session.empresa;
        this.intSucursal = session.sucursal;
        this.intPOSLog = null;
        this.strTipo = null;
        this.strDescripcion = null;
        this.datFechaAlta = null;
        this.strUsuarioAlta = session.usuario;
        this.strMaquinaAlta = null;
        this.datFechaMod = null;
        this.strUsuarioMod = null;
        this.strMaquinaMod = null;
    }

    async Save() {
        const session = new Session();

        const response = await axios.post("POS_SaveLog", this, {
            headers: session
        })
        return response.data;
    }
}


class POSEnc {
    constructor() {
        this.intPOSEnc= 0;
            this.intPOSRegister= 0;
            this.intFolio= 0;
            this.intCliente= 0;
            this.intEmpleado= 0;
            this.dblSubtotal= 0.00;
            this.dblIVA= 0.00;
            this.dblIEPS= 0.00;
            this.dblTotal= 0.00;
            this.dblPorcentajeDescuento= 0.00;
            this.intGrupo= 0;
            this.strNota= '';
            this.strUsuarioPOS= '';
            this.intEstatus= 1;
            this.intPOSEncSincronizado= 0;
            this.intSincronizado= 0;
            this.strTipoServicio= '';
            this.datHora= '';
            this.strMesero= '';
            this.strRepartidor= '';
            this.intPOSTipoServicio= 0;
            this.intDomicilioEmpleado= 0;
            this.datDomicilioFechaSale= null;
            this.dblDomicilioPagaCon= 0.00;
            this.dblPropina= 0.00;
            this.strMesa= '';
            this.intOrden= 0;
            this.strUsuarioCancelacion= '';
            this.datFechaCancelacion= null;
            this.intPOSMotivoCancelacion= 0;
            this.strComentarioCancelacion= '';
            this.datFechaPrepara= null;
            this.intCancelacionDevolucion= 0;
            this.datFechaFin= null;
            this.strCantidadLetra= '';
            this.dblTotalPagado= 0.00;
            this.dblCambio= 0.00;
            this.intPOSDescuento= 0;
            this.datFechaDescuento= null;
            this.strUsuarioDescuento= '';
            this.strClaveDescuento= '';
            this.intPOSMotivoDescuento= 0;
            this.strReferenciaDescuento= '';
            this.intPOSRegisterOriginal= 0;
            this.intTipoDescuento= 0;
            this.uidGuid= guid();
            this.strNombre= '';
            this.strTelefono= '';
            this.strDireccion= '';
            this.strPoblacion= '';
            this.strColonia= '';
            this.strReferencia= '';
            this.strTelefono2= '';
            this.strEntreCalle= '';
            this.intRequiereFactura= 0;
            this.dblEnvio1= 0.00;
            this.dblEnvio2= 0.00;
            this.dblEnvio3= 0.00;
            this.dblEnvio4= 0.00;
            this.dblEnvio5= 0.00;
            this.intPedidoQP= 0;
            this.intFolioEmpleado= 0;
            this.strCardId= '';
            this.strUId= '';
            this.intPOSCobraComanda= 0;
            this.strBiometrico= '';
            this.intPOSMesero= 0;
            this.intPOSTipoVenta= 0;
            this.dblImporteDescuento= 0;
            this.posDet= [];
            this.posEncPago= []
            this.intHotReservacion = 0;
            this.dblISH= 0.00;
            this.dblpagacon= 0.0;
    }
}

class POSHerencia {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intPOSHerencia = 0;
        this.intPOSRegisterOriginal = null;
        this.intPOSRegisterHerencia = null;
        this.strUsuarioOriginal = '';
        this.strUsuarioHerencia = '';
        this.datFecha = null;
        this.intEstatus = 1;
        this.intCuentas = 0;
        this.strTerminal = '';

    }
}



class POSInfo {
    constructor() {
        return {
            strCorreo: "correo_empresa@gmail.com",
            strNombre: "Empresa para pruebas",
            strDireccion: "Calle Prueba, Col. Prueba, Monterrey, Nuevo Leon, Mexico",
            strTelefono: "811245878",
            strRFC: "XAXX010101000",
            strNombreLegal: "Empresa de Pruebas Regiomontana S.A. de C.V.",
            strResponsable:"L",
            strColonia:"L",
            strCiudad: "L",

        }
    }
}
class POSCliente {
    constructor() {
        return {
            strNombre: '',
            strDireccion:'',
            strEntreCalle:'',
            strTelefono: '',
            strColonia: '',
            strReferencia: '',
        }
    }
}

// class POSValidaciones {
//     constructor() {
//         return {
//             id: 0,
//             tipo: '',
//             intPOSEnc: 0,
//             dblTotal: 0.00,
//             strMesa: '',
//             strCliente:'',
//             dblPorcentajeDescuento: 0.00,
//             intCancelacionDevolucion: 0,
//             datFechaCancelacion: null,
//             intPOSMotivoCancelacion: 0,
//             strComentarioCancelacion: '',
//             strUsuarioCancelacion:'',
//             strMotivoCancelacion: '',
//             datFechaDescuento: null,
//             intPOSDescuento: 0,
//             strClaveDescuento: '',
//             strReferenciaDescuento: '',
//             strUsuarioDescuento: '',
//             intPOSMotivoDescuento: 0,
//             strMotivoDescuento:''
//         }
//     }
// }

class POSEncPago {
    constructor() {
        return {
            intPOSEncPago: 0,
            intFolio: 0,
            intPOSEnc: 0,
            intNumeroCuenta: 0,
            intTipoPago: 0,
            strNombre: '',
            dblImporte: 0.00,
            strReferencia: '',
            dblCambio: 0.00,
            intGrupo: 0,
            intEstatus: 1,
            uidGuid: guid(),
            intPOSRequiereFirma: 0,
            strUsuario: '',
            strContrasenia: '',
            strBiometrico: '',
            intPOSTerminalPago: 0,
            strEmpleadoBiometrico: '',
            intEmpleadoBiometrico: 0,
            datFechaAlta: null,
            datFechaMod: null
        }
    }
}

class POSRegister {
    constructor() {
        return {
            intPOSRegister: 0,
            intPOSRegisterPadre: 0,
            intFolio: 0,
            datFechaInicio: null,
            datFechaFin: null,
            strUsuarioPOS: '',
            strComentario: '',
            dblImporte: 0.00,
            dblImporteEntregado: 0.00,
            dblDiferencia: 0.00,
            intEstatus: 0,
            intPOSTipoMovimientoTurno: 1,
            dblDenominacion20: 0.00,
            dblDenominacion50: 0.00,
            dblDenominacion100: 0.00,
            dblDenominacion200: 0.00,
            dblDenominacion500: 0.00,
            dblDenominacion1000: 0.00,
            dblMonedas: 0.00,
            dblOtrosPagos: 0.00,
            intEmpleado: 0,
           // datHora: '',
            intIntentos: 0,
            intCorteGlobal: 0,
            POSValidaciones: null,
        }
    }
}

class POSDetArticuloConfiguracion {
    constructor(x, index) {
        this.intPOSDetArticuloConfiguracion = 0;
        this.intPOSDet = 0;
        this.uidGUID = guid();
        this.intArticuloConfiguracion = x.intArticuloConfiguracion;
        this.intArticuloConfiguracionPadre = x.intArticuloConfiguracionPadre;
        this.strGUIDDet = null;
        this.strNombre = x.strNombre;
        this.strNombreCorto = x.strNombreCorto;
        this.intOrdenImpresion = index;
        this.dblPrecio = x.dblPrecio;
        this.intRequierePreparacion = 1;
        this.intExtra = x.intExtra;
        this.intSaltoLinea = 0;
        this.intCantidadInferior = 0;
        this.strClaveArticulo = x.strClaveArticulo;
        this.strUsuarioAutoriza = null;
        this.strMensaje = null;
        this.intEmpresa = 0;
        this.intCantidad=x.intCantidad;
      }
}

class POSDet {
    constructor() {
        return {
            intPOSDet: 0,
            intPOSEnc: 0,
            intFolio: 0,
            intArticulo: 0,
            strNombreCorto: '',
            strNombre: '',
            strFamilia: '',
            dblPU: 0.00,
            dblCantidad: 0.00,
            dblTotal: 0.00,
            dblPorcentajeDescuento: 0.00,
            dblPorcentajeIVA: 0.00,
            dblPorcentajeIEPS: 0.00,
            dblPorcentajeISH: 0.00,
            strNota: '',
            uidGuid: guid(),
            intNumeroCuenta: 0,
            intEstatus: 1,
            intGrupo: 0,
            blOcultar: false,
            intArticuloConfiguracion: 0,
            datFechaCancelacion: null,
            strUsuarioCancelacion: '',
            intPOSMotivoCancelacion: 0,
            strComentarioAutoriza: '',
            intPOSDescuento: 0,
            datFechaDescuento: null,
            strUsuarioDescuento: '',
            strClaveDescuento: '',
            intPOSMotivoDescuento: 0,
            strReferenciaDescuento: '',
            intTipoDescuento: 0,
            intOrdenImpresion: 0,
            intCancelacionDevolucion: 0,
            intPOSRegister: 0,
            intPOSImpresora: 0,
            POSDetArticuloConfiguracion: null,
           
            open: false,
            icon: "fas fa-arrow-up"
        }
    }
}

export {
    POSEnc,
    POSDetFunctions,
    POSInfo,
    POSMeseroFunctions,
    POSEncPagoFunctions,
    POSDet,
    POSEncPago,
    POSEncFunctions,
    POSTerminalFunctions,
    POSRegisterFunctions,
    POSRegister,
    POSHerencia,
    POSSalidaCaja,
    POSLogImpresion,
    POSLog,
    POSInventarioFunctions,
    POSDetArticuloConfiguracion,
    POSValidaciones,
    POSCliente
};